const VIEW_PRODUCT_SPECS_MODAL = "VIEW_PRODUCT_SPECS_MODAL:";

export function ViewProductSpec(productID, productVariantID) {
  return VIEW_PRODUCT_SPECS_MODAL + productID + ":" + productVariantID;
}

export function IsViewProductSpec(itm) {
  return itm.startsWith(VIEW_PRODUCT_SPECS_MODAL);
}

export function GetProductSpecParams(itm) {
  let val = itm.split(":");
  return {
    productID: val[1],
    productVariantID: val[2],
  };
}
