import * as React from "react";
import { OrderProgressBar } from "../order/OrderProgressBar";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ChooseProductStage } from "../order/stages/ChooseProductStage";
import { MakeProcessingFeePaymentV2 } from "../order/stages/MakeProcessingFeePaymentStage";
import { ChoosePromptStage } from "../order/stages/ChoosePromptStage";
import { ChooseImageStage } from "../order/stages/ChooseImageStage";
import { ConfigureProductStage } from "../order/stages/ConfigureProductStage";
import {
  PreviewOrderStage,
  ChooseNewCountryValue,
} from "../order/stages/PreviewOrderStage";
import { ReviewOrderStage } from "../order/stages/ReviewOrderStage";
import { ModalStyles } from "../utils/modal";
import Modal from "@mui/material/Modal";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { GetUserMetadata } from "../utils/user";
import {
  GetOrder,
  CreateBaseImages,
  ChooseOrderImage,
  ConfigureOrderProduct,
  ChooseOrderProduct,
  GetDemoContent,
} from "../utils/api";
import { ChooseOrderCountry } from "../order/ChooseOrderCountry";
import {
  ParseDemoParamsFromQueryParams,
  ParseDemoStageFromQueryParams,
  WriteQueryParam,
  UpdateArrQueryParam,
} from "../utils/demo";
import loadingGif from "./stages/assets/loading-bucket.gif";
import loadingBucketStill from "./stages/assets/loading-bucket-still.png";

const loadingAIImagesTopText = "Making the AI draw a few images.";
const loadingAIImagesBotText = "This may take a minute or two!";
const loadingMockupsTopText = "Generating a mockup.";
const loadingMockupsBotText = "This may take a minute!";

export function OrderStepper({
  user,
  order,
  setOrder,
  setModalURL,
  demo,
  demoPrompts,
}): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const serviceFeePaid = searchParams.get("service_fee_paid") === "true";
  const order_id = searchParams.get("order_id");
  const [showLoadingPage, setShowLoadingPage] = React.useState(undefined);

  const blockWhilePollingForOrderStatus = async (desiredStatus, orderID) => {
    var pollInterval = setInterval(async () => {
      const tmpOrder = await GetOrder(user, orderID);

      if (
        tmpOrder !== undefined &&
        (tmpOrder.status === desiredStatus || tmpOrder.status === "failed")
      ) {
        clearInterval(pollInterval);

        setOrder(tmpOrder);
        setShowLoadingPage(undefined);
        return;
      }
    }, 1000);
  };

  const handleStageButton = async (params) => {
    const handlerMap = {
      started: {
        loading: {
          topText:
            order?.prompt_and_images?.length > 0
              ? loadingMockupsTopText
              : "Saving the product.",
          bottomText:
            order?.prompt_and_images?.length > 0
              ? loadingMockupsBotText
              : "One Second!",
        },
        invalid: !params || (params && !params.product),
        callFn: async function () {
          await ChooseOrderProduct(user, order.id, params.product);
        },
        demoFn: function () {
          WriteQueryParam(
            "stage",
            order?.prompt_and_images?.length > 0 ? 4 : 2
          );
          WriteQueryParam("product", params.product);
        },
        nextStatus:
          order?.prompt_and_images?.length > 0
            ? "generated_mockups"
            : "chosen_product",
      },
      chosen_product: {
        loading: {
          topText: loadingAIImagesTopText,
          bottomText: loadingAIImagesBotText,
        },
        invalid: !params || (params && !params.prompt),
        callFn: async function () {
          await CreateBaseImages(user, order.id, params.prompt);
        },
        demoFn: function () {
          UpdateArrQueryParam("prompt", params.prompt);
          WriteQueryParam("stage", 3);
        },
        nextStatus: "generated_images",
      },
      generated_images: {
        loading: {
          topText: skipProductConfiguration(order.product_info?.id)
            ? loadingMockupsTopText
            : "Saving Image Choice.",
          bottomText: skipProductConfiguration(order.product_info?.id)
            ? loadingMockupsBotText
            : "One Second!",
        },
        invalid: !params || (params && !params.image_id),
        callFn: async function () {
          await ChooseOrderImage(user, order.id, [
            { image_id: params.image_id, usage: "front" },
          ]);
        },
        demoFn: function () {
          WriteQueryParam("image_id", params.image_id);
          WriteQueryParam("stage", 4);
        },
        nextStatus: skipProductConfiguration(order.product_info?.id)
          ? "generated_mockups"
          : "chosen_image",
      },
      chosen_image: {
        loading: {
          topText: loadingMockupsTopText,
          bottomText: loadingMockupsBotText,
        },
        invalid: !params,
        callFn: async function () {
          await ConfigureOrderProduct(user, order.id, params);
        },
        nextStatus: "generated_mockups",
      },
    };

    const next = handlerMap[order.status];
    if (!next || next.invalid) {
      return;
    }

    if (demo === true) {
      const timeStart = new Date();

      setShowLoadingPage(next.loading);
      next.demoFn();
      const demoParams = await ParseDemoParamsFromQueryParams();
      const demoContent = await GetDemoContent(
        ParseDemoStageFromQueryParams(),
        demoParams
      );
      setOrder(demoContent.order);

      var millisecondsSinceStart =
        (new Date().getTime() - timeStart.getTime()) / 100;
      if (millisecondsSinceStart > 500) {
        setShowLoadingPage(undefined);
      } else {
        setTimeout(function () {
          setShowLoadingPage(undefined);
        }, 800 - millisecondsSinceStart);
      }
    } else {
      setShowLoadingPage(next.loading);
      next.callFn();

      await blockWhilePollingForOrderStatus(next.nextStatus, order.id);
    }
  };

  // Force a download of the Gif
  const loadingGIF = new Image();
  loadingGIF.src = loadingGif;
  const loadingGIFStill = new Image();
  loadingGIFStill.src = loadingBucketStill;

  React.useEffect(() => {
    if (!order) {
      return;
    }

    if (order.status === "initiated" && serviceFeePaid) {
      blockWhilePollingForOrderStatus("started", order.id);
    } else if (order.status === "generating_images") {
      blockWhilePollingForOrderStatus("generated_images", order.id);
    } else if (order.status === "product_configured") {
      blockWhilePollingForOrderStatus("generated_mockups", order.id);
    }
  }, [order]);

  return (
    <div style={{ minHeight: "94vh" }}>
      <OrderProgressBar stage={getStageFromOrderStatus(order)} demo={demo} />
      {showLoadingPage !== undefined ||
      order?.status === "generating_images" ||
      order?.status === "product_configured" ? (
        <LoadingNextStage
          topText={
            showLoadingPage !== undefined
              ? showLoadingPage.topText
              : mapToLoadingText(true, order?.status)
          }
          bottomText={
            showLoadingPage !== undefined
              ? showLoadingPage.bottomText
              : mapToLoadingText(false, order?.status)
          }
          loadingGIF={loadingGIF}
        />
      ) : (
        <React.Fragment>
          {order.status === "initiated" ? (
            <MakeProcessingFeePaymentV2
              order={order}
              serviceFeePaid={serviceFeePaid}
            />
          ) : null}
          {order.status === "started" ? (
            <ChooseProductStage
              order={order}
              handleStageButton={handleStageButton}
              setModalURL={setModalURL}
            />
          ) : null}
          {order.status === "chosen_product" ? (
            <ChoosePromptStage
              order={order}
              handleStageButton={handleStageButton}
              demo={demo}
              demoPrompts={demoPrompts}
            />
          ) : null}
          {order.status === "generated_images" ? (
            <ChooseImageStage
              order={order}
              handleStageButton={handleStageButton}
              setModalURL={setModalURL}
              setOrder={setOrder}
              user={user}
              demo={demo}
            />
          ) : null}
          {order.status === "chosen_image" ? (
            <ConfigureProductStage
              order={order}
              handleStageButton={handleStageButton}
            />
          ) : null}
          {order.status === "generated_mockups" ? (
            <PreviewOrderStage
              order={order}
              handleStageButton={handleStageButton}
              setModalURL={setModalURL}
              setOrder={setOrder}
              user={user}
              demo={demo}
            />
          ) : null}
          {order.status === "product_ordered" ||
          order.status === "product_shipped" ? (
            <ReviewOrderStage
              order={order}
              demo={demo}
              setModalURL={setModalURL}
            />
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
}

function LoadingNextStage({ topText, bottomText }): JSX.Element {
  return (
    <Box class="order-stage-box">
      <div style={{ textAlign: "center", marginTop: "1em" }}>
        <img
          style={{ minHeight: "157px", minWidth: "157px" }}
          src={loadingGif}
          alt={loadingBucketStill}
        />
        <br></br>
        <span class="loading-top-text">{topText}</span>
        <br></br>
        <span class="loading-bottom-text">{bottomText}</span>
      </div>
    </Box>
  );
}

function getStageFromOrderStatus(order) {
  const resp = {
    initiated: 0,
    started: 1,
    chosen_product: 2,
    generating_images: 2,
    generated_images: 2,
    chosen_image: 2,
    product_configured: 2,
    generated_mockups: 3,
    product_ordered: 4,
    product_shipped: 4,
    failed: -1,
  };

  const val = resp[order?.status];
  if (val >= -1) {
    return val;
  }

  return -1;
}

function skipProductConfiguration(product) {
  const needConfiguration = {
    568: true,
  };

  return !(needConfiguration[product] === true);
}

function mapToLoadingText(top, status) {
  if (top) {
    const m = {
      generating_images: loadingAIImagesTopText,
      product_configured: loadingMockupsTopText,
    };

    if (m[status]) {
      return m[status];
    }

    return "Loading next page...";
  }

  const m = {
    generating_images: loadingAIImagesBotText,
    product_configured: loadingMockupsBotText,
  };

  if (m[status]) {
    return m[status];
  }

  return "Good things are in the works!";
}
