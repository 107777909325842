import axios from "axios";
import { IsProd } from "./env";

export async function PingServer() {
  await makeGetRequest("/");
}

export async function CheckInUser(user) {
  const userObj = {
    id: user.user.sub,
    name: user.user.name,
    picture: user.user.picture,
    email: user.user.email,
    email_verified: user.user.email_verified,
  };

  const resp = await makePostRequest(
    "/user/checkin",
    userObj,
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log("Failed to check in user: ", getError(resp));
    return;
  }
}

export async function GetOrder(user, orderID) {
  const resp = await makePostRequest(
    "/order",
    {
      id: orderID,
    },
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log("Failed to get order: ", getError(resp));
    return;
  }

  return getResp(resp).data;
}

export async function CreateOrder(user, approximateLocation) {
  const resp = await makePostRequest(
    "/create/order",
    {
      approximate_location: approximateLocation,
    },
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log("Failed to create order: ", getError(resp));
    return;
  }

  return getResp(resp).data;
}

export async function GetUserOrders(user, limit = 3) {
  const resp = await makePostRequest(
    "/orders",
    {
      paging: {
        limit: limit,
      },
    },
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log("Failed to get orders: ", getError(resp));
    return;
  }

  return getResp(resp).data.orders;
}

export async function CreateBaseImages(user, orderID, prompt) {
  const resp = await makePostRequest(
    "/create/base/images",
    {
      order_id: orderID,
      prompt: prompt,
    },
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log("Failed to get orders: ", getError(resp));
    return;
  }

  return true;
}

export async function ChooseOrderImage(user, orderID, choices) {
  const resp = await makePostRequest(
    "/choose/image",
    {
      order_id: orderID,
      choices: choices,
    },
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log("Failed to choose image: ", getError(resp));
    return;
  }

  return true;
}

export async function ConfigureOrderProduct(user, orderID, configuration) {
  const resp = await makePostRequest(
    "/product/configuration",
    {
      order_id: orderID,
      configuration: JSON.stringify(configuration),
    },
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log("Failed to configure product: ", getError(resp));
    return;
  }

  return getResp(resp).data;
}

export async function GetOrderEstimate(user, orderID) {
  const resp = await makePostRequest(
    "/order/estimate",
    {
      order_id: orderID,
    },
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log("Failed to get order estimate: ", getError(resp));
    return;
  }

  return getResp(resp).data;
}

export async function GetProducts() {
  const resp = await makeGetRequest("/products");
  if (isError(resp)) {
    console.log("Failed to get products: ", getError(resp));
    return;
  }

  return getResp(resp).data;
}

export async function GetProduct(productSpecParams) {
  const resp = await makePostRequest("/product", {
    product_id: Number(productSpecParams.productID),
    product_variant_id: Number(productSpecParams.productVariantID),
  });
  if (isError(resp)) {
    console.log("Failed to get products: ", getError(resp));
    return;
  }

  return getResp(resp).data;
}

export async function ChooseOrderProduct(user, orderID, product) {
  const resp = await makePostRequest(
    "/order/product",
    {
      order_id: orderID,
      product_id: product.productID,
      variant_id: product.variantID,
    },
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log("Failed to choose order product: ", getError(resp));
    return;
  }

  return true;
}

export async function MakeOrderProductPayment(user, orderID) {
  const resp = await makePostRequest(
    "/order/pay",
    {
      order_id: orderID,
      payment_type: "product",
    },
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log("Failed to create order product payment: ", getError(resp));
    return;
  }

  return getResp(resp).data.checkout_url;
}

export async function MakeOrderProcessingFeePayment(user, orderID) {
  const resp = await makePostRequest(
    "/order/pay",
    {
      order_id: orderID,
      payment_type: "processing_fee",
    },
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log(
      "Failed to create order processing fee payment: ",
      getError(resp)
    );
    return;
  }

  return getResp(resp).data.checkout_url;
}

export async function SetOrderCountry(user, orderID, countryCode) {
  const resp = await makePostRequest(
    "/order/country",
    {
      order_id: orderID,
      country_code: countryCode,
    },
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log(
      "Failed to create order processing fee payment: ",
      getError(resp)
    );
    return;
  }

  return undefined;
}

export async function RewindOrder(user, orderID, rewindAction) {
  const resp = await makePostRequest(
    "/order/rewind",
    {
      order_id: orderID,
      rewind_operation: rewindAction,
    },
    buildAxiosConfig(user)
  );
  if (isError(resp)) {
    console.log(
      "Failed to create order processing fee payment: ",
      getError(resp)
    );
    return false;
  }

  return true;
}

export async function GetDemoContent(demoStage, params) {
  const resp = await makePostRequest("/demo", {
    stage: demoStage,
    params: params,
  });
  if (isError(resp)) {
    console.log(
      "Failed to create order processing fee payment: ",
      getError(resp)
    );
    return;
  }

  return getResp(resp).data;
}

export async function CreateDemoPayment(params, previousOrder, user) {
  let demoPayURL = "/demo/pay";
  let userConfig = undefined;
  if (user) {
    userConfig = buildAxiosConfig(user);
    demoPayURL = "/demo/user/pay";
  }

  const resp = await makePostRequest(
    demoPayURL,
    {
      params: params,
      previous_order: previousOrder,
      cancel_url: window.location.href,
    },
    userConfig
  );
  if (isError(resp)) {
    console.log(
      "Failed to create order processing fee payment: ",
      getError(resp)
    );
    return;
  }

  return getResp(resp).data;
}

async function makeGetRequest(url, config) {
  try {
    const response = await axios.get(apiBaseURL() + url, config);
    return makeSuccess(response);
  } catch (error) {
    return makeError(error);
  }
}

async function makePostRequest(url, body, config) {
  try {
    const response = await axios.post(apiBaseURL() + url, body, config);
    return makeSuccess(response);
  } catch (error) {
    return makeError(error);
  }
}

function makeError(err) {
  return [err, undefined];
}

function makeSuccess(resp) {
  return [undefined, resp];
}

function isError(resp) {
  return getError(resp) !== undefined;
}

function getError(resp) {
  return resp[0];
}

function getResp(resp) {
  return resp[1];
}

function buildAxiosConfig(user) {
  return {
    headers: {
      Authorization: "Bearer " + user.accessToken,
    },
  };
}

function apiBaseURL() {
  if (IsProd()) {
    return "https://artgen-be.herokuapp.com";
  }

  return "http://localhost:8080";
}
