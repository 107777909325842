const artGenUserKey = "artgen-user";

export async function GetUserMetadata(
  isAuthenticated,
  user,
  getAccessTokenSilently
) {
  // The user has recently logged in and been redirected back here, lets update
  // the user information + access token inside localStorage
  if (isAuthenticated && user) {
    const userObj = await getUserAccessToken(user, getAccessTokenSilently);
    return userObj;
  }

  // // Check if the user has authenticated recently and we have the data stored in
  // // localStorage
  // const userInStorage = getUserFromStorage();
  // if (userInStorage !== undefined) {
  //   return userInStorage;
  // }

  return undefined;
}

export function UserLogout() {
  clearUser();
}

export function getUserFromStorage() {
  const tenMinutesInMS = 80 * 60 * 1000;

  let userObj;
  const userObjStr = localStorage.getItem(artGenUserKey);
  if (userObjStr !== undefined) {
    userObj = JSON.parse(userObjStr);
  }

  if (
    !userObj ||
    (userObj && (!userObj.user || !userObj.accessToken || !userObj.storedAt))
  ) {
    return undefined;
  }
  if (!(new Date(userObj.storedAt) > Date.now() - tenMinutesInMS)) {
    clearUser();
    return undefined;
  }

  return userObj;
}

export async function getUserAccessToken(user, getAccessTokenSilently) {
  try {
    const token = await getAccessTokenSilently({
      audience: "artgen-be-srv",
      domain: "dev-wuzhje5xtqu5ohb7.us.auth0.com",
    });

    const userObj = {
      user,
      accessToken: token,
      storedAt: new Date(),
    };
    // writeUser(JSON.stringify(userObj));
    return userObj;
  } catch (e) {
    console.log("Toolbar Err: ", e);
    return undefined;
  }
}

function writeUser(user) {
  localStorage.setItem(artGenUserKey, user);
}

function clearUser() {
  localStorage.removeItem(artGenUserKey);
}
