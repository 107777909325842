import axios from "axios";

const ipKey = "SAVED_IP_LOCATION";

export async function GetIPLocation() {
  return await _getIPLocation();
}

async function _getIPLocation() {
  const savedIPVal = localStorage.getItem(ipKey);
  if (!savedIPVal) {
    return await _storeIPLocation();
  }

  const savedIPParsed = JSON.parse(savedIPVal);

  var hours = Math.abs(new Date() - new Date(savedIPParsed.ts)) / 36e5;
  if (hours > 12) {
    return await _storeIPLocation();
  }

  return savedIPParsed.locationVal;
}

async function _storeIPLocation() {
  try {
    const location = await axios.get("https://ipapi.co/json/");

    const locationAndTimestamp = {
      ts: new Date(),
      locationVal: {
        country_code: location.data.country_code,
        city: location.data.city,
        zip: location.data.postal,
        region_code: location.data.region_code,
        lat: location.data.latitude,
        long: location.data.longitude,
      },
    };

    localStorage.setItem(ipKey, JSON.stringify(locationAndTimestamp));

    return locationAndTimestamp.locationVal;
  } catch (error) {
    console.log("Failed to get IP location! ", error);
    return undefined;
  }
}
