import * as React from "react";
import { Box } from "@mui/material";
import CategoryIcon from "@mui/icons-material/Category";
import { GetProduct } from "../utils/api";
import CircularProgress from "@mui/material/CircularProgress";
import { GetProductSpecParams } from "../utils/products";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CircleIcon from "@mui/icons-material/Circle";

export function ViewProductSpecs({ modalURL }): JSX.Element {
  const [loading, setLoading] = React.useState(true);
  const [productInfo, setProductInfo] = React.useState(undefined);

  React.useEffect(() => {
    const getProductSpec = async () => {
      const productSpec = await GetProduct(GetProductSpecParams(modalURL));
      if (productSpec) {
        setProductInfo(productSpec);
        setLoading(false);
      }
    };

    getProductSpec();
  }, [modalURL]);

  return (
    <div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <React.Fragment>
          <Box class="order-stage-box">
            <div>
              <div class="choose-product-outer-circle">
                <div class="choose-product-icon-inner-circle">
                  <CategoryIcon style={{ color: "white", fontSize: "2em" }} />
                </div>
              </div>
            </div>
          </Box>
          <Box
            class="order-stage-box medium-text-align-center"
            style={{ marginTop: "1em" }}
          >
            <h2 style={{ fontFamily: "Poppins Medium" }}>
              {productInfo.name}{" "}
              <span style={{ fontFamily: "Poppins Thin" }}>info</span>
            </h2>
          </Box>
          <Box
            style={{
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
              paddingTop: "1em",
              paddingBottom: "1em",
              textAlign: "center",
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
            }}
          >
            <h4>{productInfo.description}</h4>
          </Box>
          <Box
            style={{
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
              paddingTop: "1em",
              paddingBottom: "1em",
              textAlign: "center",
              borderTop: "1px solid #ccc",
              borderBottom: "1px solid #ccc",
            }}
          >
            <List>
              {productInfo.spec_items.map((itm) => (
                <ListItem disablePadding>
                  <ListItemIcon>
                    <CircleIcon />
                  </ListItemIcon>
                  <ListItemText primary={itm} />
                </ListItem>
              ))}
            </List>
          </Box>
        </React.Fragment>
      )}
    </div>
  );
}
