import * as React from "react";
import { Box } from "@mui/material";
import Faq from "react-faq-component";
import { Footer } from "../global-components/Footer";

export function HelpCenter(): JSX.Element {
  return (
    <React.Fragment>
      <Box className="hero-image" style={{ minHeight: "100vh" }}>
        <Box class="centered-order-stage-content large-small">
          <h2 style={{ textAlign: "center", marginBottom: "2em" }}>
            The service we aim to offer!
          </h2>
          <h3>
            Our website offers a wide range of unique and stylish merchandise
            featuring stunning images generated by AI (Dalle). Customers can
            browse and purchase our products with ease, including stickers,
            mugs, cards, coasters, and masks (+ many more). All of our products
            are printed on high-quality materials and are the perfect way to
            express your love for AI and technology. Our clear and concise
            product descriptions, combined with high-quality images, make it
            simple for customers to find exactly what they're looking for.
            Whether you're looking for a new reusable Mask, a stylish mug, or a
            set of coasters, our website has everything you need to show your
            love for AI in style.
          </h3>
        </Box>
        <div
          style={{
            minHeight: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <h2 style={{ textAlign: "center", marginBottom: "2em" }}>
              Please see if any of the below helps with your issue.
            </h2>
            {allData.map((d) => (
              <Box
                class="centered-order-stage-content large-small"
                style={{
                  backgroundColor: "rgb(29, 26, 39, 0.9)",
                  padding: "1em",
                  borderRadius: 25,
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: "2000px",
                }}
              >
                <Faq
                  data={d}
                  styles={{
                    bgColor: "transparent",
                    titleTextSize: "1em",
                    rowTitleTextSize: "1em",
                    titleTextColor: "white",
                    rowTitleColor: "white",
                    rowContentColor: "white",
                    arrowColor: "white",
                  }}
                />
              </Box>
            ))}
            <h2 style={{ textAlign: "center", marginTop: "2em" }}>
              If not we are always available at support@artgen.store
            </h2>
          </div>
        </div>
      </Box>
      <Footer />
    </React.Fragment>
  );
}

const fulfillmentPolicy = {
  rows: [
    {
      title: "How we fulfill your order",
      content: (
        <React.Fragment>
          <p>Our fulfillment and shipping schedule works as follows: </p>
          <p>
            After you place an order, the object you ordered will be printed and
            prepared for shipping. This process typically takes 2-5 days.
          </p>
          <p>
            Once the object is ready, it will be shipped out to you right away.
            Shipping takes an additional 2-7 days, depending on your location.
            So in total, you can expect to receive your order within 4-12 days
            after placing it.
          </p>
        </React.Fragment>
      ),
    },
  ],
};

const refundPolicy = {
  rows: [
    {
      title: "Refund Policy",
      content: (
        <React.Fragment>
          <p>We cannot offer refunds for returns.</p>
          <p>
            Each product is printed with a unique image, this means that once an
            item has been printed, it can no longer be resold. As a result, we
            cannot offer refunds for returns as it would not be financially
            feasible for our business. We apologize for any inconvenience this
            may cause and appreciate your understanding.
          </p>
        </React.Fragment>
      ),
    },
  ],
};

const mockupDelays = {
  rows: [
    {
      title: "Mockup Generation and Potential Delays",
      content: (
        <React.Fragment>
          <p>
            We want to let you know that this is a normal occurrence when a high
            volume of users is accessing the website at the same time. Our
            servers are working to process all user requests as quickly as
            possible, including generating mockups. We understand that this can
            be frustrating and we appreciate your patience while we work to
            improve the website's infrastructure to handle more users in the
            future. Thank you for your understanding.
          </p>
        </React.Fragment>
      ),
    },
  ],
};

const dynamicPricing = {
  rows: [
    {
      title: "Dynamic Product Pricing",
      content: (
        <React.Fragment>
          <p>
            Please note that we have a dynamic pricing system in place. This
            means that the prices of our products or services may change
            frequently based on factors such as supply and demand, production
            costs, and shipping fees. As a result, you may see different prices
            for the same product at different times. The final price of your
            purchase will be confirmed when you press the "pay" button during
            the checkout process. This allows us to ensure that our prices
            remain competitive and reflect the current market conditions. We
            apologize for any inconvenience this may cause and thank you for
            your understanding.
          </p>
        </React.Fragment>
      ),
    },
  ],
};

const countryChoiceBeforeCheckout = {
  rows: [
    {
      title: "Why We Require You to Choose Your Country Before Checkout",
      content: (
        <React.Fragment>
          <p>
            In order to provide you with accurate shipping prices, we require
            you to select your country before proceeding to checkout. This is
            because shipping costs can vary depending on the manufacturer and
            destination. By selecting your country, we can ensure that you are
            given the correct shipping prices for your order. Thank you for your
            understanding.
          </p>
        </React.Fragment>
      ),
    },
  ],
};

const allData = [
  fulfillmentPolicy,
  mockupDelays,
  dynamicPricing,
  refundPolicy,
  countryChoiceBeforeCheckout,
];
