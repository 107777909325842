import * as React from "react";
import { IsSmallSize } from "../utils/size";

export function OrderProgressBar({ stage, demo }): JSX.Element {
  return (
    <div class="progress-bar centered-order-stage-content large-small">
      {demo !== true ? (
        <React.Fragment>
          <div class="progress-bar-stage overview">
            <div
              class={"progress-bar-stage-inner" + (stage >= 0 ? " active" : "")}
            ></div>
            <div
              class={"progress-bar-stage-text" + (stage >= 0 ? " active" : "")}
            >
              Overview
            </div>
          </div>
          <div
            class={
              "progress-bar-stage-connector no-mobile-view" +
              (stage > 0 ? " active" : "")
            }
          ></div>
          <div
            class={
              "progress-bar-stage-connector no-mobile-view" +
              (stage > 0 ? " active" : "")
            }
          ></div>
          <div
            class={
              "progress-bar-stage-connector" + (stage > 0 ? " active" : "")
            }
          ></div>
          <div
            class={
              "progress-bar-stage-connector no-mobile-view" +
              (stage > 0 ? " active" : "")
            }
          ></div>
          <div
            class={
              "progress-bar-stage-connector no-mobile-view" +
              (stage > 0 ? " active" : "")
            }
          ></div>
        </React.Fragment>
      ) : null}
      <div class="progress-bar-stage prompt">
        <div
          class={"progress-bar-stage-inner" + (stage >= 1 ? " active" : "")}
        ></div>
        <div class={"progress-bar-stage-text" + (stage >= 1 ? " active" : "")}>
          Product
        </div>
      </div>
      <div
        class={
          "progress-bar-stage-connector no-mobile-view" +
          (stage > 1 ? " active" : "")
        }
      ></div>
      <div
        class={
          "progress-bar-stage-connector no-mobile-view" +
          (stage > 1 ? " active" : "")
        }
      ></div>
      <div
        class={"progress-bar-stage-connector" + (stage > 1 ? " active" : "")}
      ></div>
      <div
        class={
          "progress-bar-stage-connector no-mobile-view" +
          (stage > 1 ? " active" : "")
        }
      ></div>
      <div
        class={
          "progress-bar-stage-connector no-mobile-view" +
          (stage > 1 ? " active" : "")
        }
      ></div>
      <div class="progress-bar-stage images">
        <div
          class={"progress-bar-stage-inner" + (stage >= 2 ? " active" : "")}
        ></div>
        <div class={"progress-bar-stage-text" + (stage >= 2 ? " active" : "")}>
          Image
        </div>
      </div>
      <div
        class={
          "progress-bar-stage-connector no-mobile-view" +
          (stage > 2 ? " active" : "")
        }
      ></div>
      <div
        class={
          "progress-bar-stage-connector no-mobile-view" +
          (stage > 2 ? " active" : "")
        }
      ></div>
      <div
        class={"progress-bar-stage-connector" + (stage > 2 ? " active" : "")}
      ></div>
      <div
        class={
          "progress-bar-stage-connector no-mobile-view" +
          (stage > 2 ? " active" : "")
        }
      ></div>
      <div
        class={
          "progress-bar-stage-connector no-mobile-view" +
          (stage > 2 ? " active" : "")
        }
      ></div>
      <div class="progress-bar-stage products">
        <div
          class={"progress-bar-stage-inner" + (stage >= 3 ? " active" : "")}
        ></div>
        <div class={"progress-bar-stage-text" + (stage >= 3 ? " active" : "")}>
          Preview
        </div>
      </div>
      <div
        class={
          "progress-bar-stage-connector no-mobile-view" +
          (stage > 3 ? " active" : "")
        }
      ></div>
      <div
        class={
          "progress-bar-stage-connector no-mobile-view" +
          (stage > 3 ? " active" : "")
        }
      ></div>
      <div
        class={"progress-bar-stage-connector" + (stage > 3 ? " active" : "")}
      ></div>
      <div
        class={
          "progress-bar-stage-connector no-mobile-view" +
          (stage > 3 ? " active" : "")
        }
      ></div>
      <div
        class={
          "progress-bar-stage-connector no-mobile-view" +
          (stage > 3 ? " active" : "")
        }
      ></div>
      <div class="progress-bar-stage order">
        <div
          class={"progress-bar-stage-inner" + (stage >= 4 ? " active" : "")}
        ></div>
        <div class={"progress-bar-stage-text" + (stage >= 4 ? " active" : "")}>
          Order
        </div>
      </div>
    </div>
  );
}
