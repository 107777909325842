import * as React from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import WallpaperOutlinedIcon from "@mui/icons-material/WallpaperOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { RewindOrder, GetOrder } from "../../utils/api";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import IconButton from "@mui/material/IconButton";
import {
  WriteQueryParam,
  ParseDemoStageFromQueryParams,
  ParseDemoParamsFromQueryParams,
} from "../../utils/demo";
import { GetDemoContent } from "../../utils/api";
import { IsSmallSize } from "../../utils/size";

export function ChooseImageStage({
  order,
  handleStageButton,
  setModalURL,
  setOrder,
  user,
  demo,
}): JSX.Element {
  const [selectedImage, setSelectedImage] = React.useState("");
  const [rewindingOrder, setRewindingOrder] = React.useState(false);

  const handleRewind = async () => {
    setRewindingOrder(true);
    const resp = await RewindOrder(user, order.id, "add-new-prompt");
    if (resp) {
      const tmpOrder = await GetOrder(user, order.id);
      setOrder(tmpOrder);
      setRewindingOrder(false);
    }
  };

  const handleDemoRewind = async () => {
    WriteQueryParam("stage", 2);
    const demoParams = await ParseDemoParamsFromQueryParams();
    const demoContent = await GetDemoContent(
      ParseDemoStageFromQueryParams(),
      demoParams
    );
    setOrder(demoContent.order);
  };

  return (
    <React.Fragment>
      <Box class="order-stage-box">
        <div>
          <div class="choose-product-outer-circle">
            <div class="choose-product-icon-inner-circle">
              <WallpaperOutlinedIcon
                style={{ color: "white", fontSize: "2em" }}
              />
            </div>
          </div>
        </div>
      </Box>
      <Box
        class="order-stage-box medium-text-align-center"
        style={{ marginTop: "0px" }}
      >
        <h1 style={{ fontFamily: "Poppins Medium" }}>
          Choose one{" "}
          <span style={{ fontFamily: "Poppins Thin" }}>generated</span> image.
        </h1>
      </Box>
      <Box>
        {order.prompt_and_images.map((promptAndImg) => (
          <div style={{ paddingBottom: "2.5em", marginTop: "1.5em" }}>
            <Box class="prompt-image-box" key={promptAndImg.prompt_id}>
              <span class="prompt-text">"{promptAndImg.prompt}"</span>
            </Box>
            {promptAndImg?.bad_request_failure ? (
              <div
                class="centered-order-stage-content"
                style={{ textAlign: "center" }}
              >
                <span>
                  <span style={{ fontFamily: "Poppins Medium" }}>
                    This prompt has been rejected by Dalle2 (
                    <a
                      href="https://labs.openai.com/policies/content-policy"
                      target="_blank"
                      style={{ fontFamily: "Poppins Medium" }}
                    >
                      Content Policy
                    </a>
                    )
                  </span>
                  <br></br>
                  <br></br>Unfortunately we do not control this. It does not
                  count as a one of your prompts for the order, please try
                  again.
                </span>
              </div>
            ) : (
              <Box class="centered-order-stage-content">
                <Grid container>
                  {promptAndImg.images.map((img) => (
                    <Grid xs={12} md={6} lg={3} key={img.id}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div>
                          <div
                            class={
                              "product-choice-outer-box" +
                              (selectedImage === img.id ? " active" : "")
                            }
                            style={{ marginTop: "1em", cursor: "pointer" }}
                            onClick={() => {
                              setSelectedImage(img.id);
                            }}
                          >
                            <div class="product-choice-inner-box">
                              <img
                                src={img.url}
                                style={{
                                  height: "inherit",
                                  borderRadius: "25px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <div>
                              <IconButton onClick={() => setModalURL(img.url)}>
                                <ZoomInIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
          </div>
        ))}
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
          marginBottom: "1em",
        }}
      >
        <div class="white-loading-button">
          {order.prompt_and_images.length >= 4 ? (
            <LoadingButton
              size="small"
              startIcon={<NotInterestedIcon />}
              loading={false}
              variant="contained"
              style={{
                marginLeft: "10px",
                backgroundColor: "rgba(0, 0, 0, 0.12)",
                fontFamily: "Source Code Pro",
                borderRadius: "25px",
                marginTop: "15px",
                fontSize: "0.875rem",
                fontWeight: 600,
                padding: "10px 25px 10px 25px",
              }}
              disabled={true}
            >
              USED ALL PROMPTS
            </LoadingButton>
          ) : (
            <LoadingButton
              size="small"
              startIcon={rewindingOrder ? null : <ArrowBackIcon />}
              loading={rewindingOrder}
              variant="contained"
              style={{
                marginLeft: "10px",
                backgroundColor: "white",
                color: "black",
                fontFamily: "Source Code Pro",
                borderRadius: "25px",
                marginTop: "15px",
                marginBottom: "2em",
                fontSize: "0.875rem",
                fontWeight: 600,
                padding: "10px 25px 10px 25px",
                border: rewindingOrder ? "2px solid #ccc" : "0px solid #ccc",
              }}
              onClick={() => {
                if (demo === true) {
                  handleDemoRewind();
                } else {
                  handleRewind();
                }
              }}
            >
              <span style={{ color: rewindingOrder ? "white" : "black" }}>
                {IsSmallSize() ? "NEW PROMPT" : "USE ANOTHER PROMPT"}
              </span>
            </LoadingButton>
          )}
        </div>
        <LoadingButton
          size="small"
          endIcon={<ArrowForwardIcon />}
          loading={false}
          variant="contained"
          style={{
            marginLeft: "10px",
            backgroundColor:
              selectedImage !== "" ? "#2C3441" : "rgba(0, 0, 0, 0.12)",
            fontFamily: "Source Code Pro",
            borderRadius: "25px",
            marginTop: "15px",
            marginBottom: "2em",
            fontSize: "0.875rem",
            fontWeight: 600,
            padding: "10px 25px 10px 25px",
          }}
          disabled={selectedImage === ""}
          onClick={() => {
            handleStageButton({ image_id: selectedImage });
          }}
        >
          {IsSmallSize() ? "SUBMIT" : "SUBMIT IMAGE"}
        </LoadingButton>
      </div>
    </React.Fragment>
  );
}
