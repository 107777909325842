import * as React from "react";
import { Box, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export function MakeProcessingFeePaymentV2({
  order,
  serviceFeePaid,
}): JSX.Element {
  return (
    <Box style={{ textAlign: "center" }}>
      {!serviceFeePaid ? (
        <React.Fragment>
          <h2>Please Submit your Payment to continue</h2>
          <Button
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            style={{
              marginLeft: "10px",
              backgroundColor: "#2C3441",
              fontFamily: "Source Code Pro",
              borderRadius: "25px",
              marginTop: "15px",
              minWidth: "25em",
              fontSize: "0.875rem",
              fontWeight: 600,
              padding: "10px",
            }}
            onClick={() => {
              window.location = order.payment_needed.checkout_url;
            }}
          >
            Pay $1 Now
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h2>Confirming your payment now...</h2>
          <br></br>
          <CircularProgress />
        </React.Fragment>
      )}
    </Box>
  );
}
