import * as React from "react";
import { Box } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Grid from "@mui/material/Grid";
import { IsSmallSize } from "../../utils/size";
import CircleIcon from "@mui/icons-material/Circle";
import IconButton from "@mui/material/IconButton";
import { SanitizeOrderID } from "../../utils/order";
import LoadingButton from "@mui/lab/LoadingButton";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { ViewProductSpec } from "../../utils/products";

export function ReviewOrderStage({ order, demo, setModalURL }): JSX.Element {
  const [trackingLinkReady, setTrackingLinkReady] = React.useState(
    extractTrackingLink(order)
  );
  const [mockupImage, setMockupImage] = React.useState(
    order.mockup_images[0].url
  );

  let customerName = order?.shipping_info?.shipping_address?.name
    ? order?.shipping_info?.shipping_address?.name
    : order?.fulfillment_info?.customer_name
    ? order?.fulfillment_info?.customer_name
    : "guest";

  return (
    <div class="centered-full-stage-content">
      <Box class="order-stage-box">
        <div>
          <div class="choose-product-outer-circle">
            <div class="choose-product-icon-inner-circle">
              <LocalShippingIcon style={{ color: "white", fontSize: "2em" }} />
            </div>
          </div>
        </div>
      </Box>
      <Box class="order-stage-box" style={{ marginTop: "0px" }}>
        <h1
          style={{ fontFamily: "Poppins Medium" }}
          class="medium-text-align-center"
        >
          Thank you{" "}
          <span style={{ fontFamily: "Poppins Thin" }}>{customerName}</span> for
          your order.
        </h1>
      </Box>
      <Grid
        container
        spacing={2}
        style={{ height: "auto", marginTop: "1em", width: "100%" }}
      >
        <Grid
          xs={12}
          md={3}
          lg={6}
          key={mockupImage}
          style={{
            borderRight: IsSmallSize() ? "0px solid #ccc" : "1px solid #ccc",
          }}
        >
          {IsSmallSize() ? (
            <OrderToplevelDetails order={order} setModalURL={setModalURL} />
          ) : null}
          <div class="preview-left-box">
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <div
                    class="product-choice-outer-box large"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setModalURL(mockupImage);
                    }}
                  >
                    <div class="product-choice-inner-box large">
                      <img
                        src={mockupImage}
                        style={{
                          height: "inherit",
                          borderRadius: "25px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {order.mockup_images.length > 1 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "0.5em",
                  }}
                >
                  {order.mockup_images.map((img) => (
                    <IconButton
                      key={img.id}
                      onClick={() => {
                        setMockupImage(img.url);
                      }}
                    >
                      <CircleIcon
                        style={{
                          color: mockupImage === img.url ? "#5895fe" : "",
                        }}
                      />
                    </IconButton>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </Grid>
        <Grid xs={12} md={6} style={{ padding: "3em", marginTop: "1em" }}>
          {IsSmallSize() ? null : (
            <OrderToplevelDetails order={order} setModalURL={setModalURL} />
          )}
          <div class="review-order-right-section top">
            <Grid
              container
              spacing={2}
              style={{
                height: "100%",
                marginTop: "0px",
                marginLeft: "0px",
                width: "100%",
              }}
            >
              <Grid xs={12} md={4}>
                <div class="review-order-order-detail-blocks">
                  <div style={{ textAlign: "center" }}>
                    <span class="review-order-inner-section-title">
                      Payment
                    </span>
                    <br></br>
                    <span class="review-order-inner-section-subtitle first-letter-capitalize">
                      {order.fulfillment_info.receipt.card_brand}{" "}
                      {order.fulfillment_info.receipt.last_4_card_numbers}
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} md={4}>
                <div class="review-order-order-detail-blocks">
                  <div style={{ textAlign: "center" }}>
                    <span class="review-order-inner-section-title">
                      Address
                    </span>
                    <br></br>
                    <span class="review-order-inner-section-subtitle">
                      {order.fulfillment_info.shipping_address.address.line1}
                      {order.fulfillment_info.shipping_address.address.line2 !==
                      ""
                        ? ", " +
                          order.fulfillment_info.shipping_address.address.line2
                        : ""}
                      <br></br>
                      {
                        order.fulfillment_info.shipping_address.address.city
                      }, {order.fulfillment_info.shipping_address.address.state}
                      ,{" "}
                      {
                        order.fulfillment_info.shipping_address.address
                          .postal_code
                      }
                    </span>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} md={4}>
                <div class="review-order-order-detail-blocks">
                  <div style={{ textAlign: "center" }}>
                    <span class="review-order-inner-section-title">Price</span>
                    <br></br>
                    <span class="review-order-inner-section-subtitle">
                      ${order.fulfillment_info.total_price}{" "}
                      {order.fulfillment_info.receipt.currency.toUpperCase()}{" "}
                      (QTY: {calculateTotalQuantity(order)})
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div class="review-order-right-section bottom">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <span
                class="preview-order-name medium-text-align-center"
                style={{ fontFamily: "Poppins" }}
              >
                Images
              </span>
            </div>
            <Box class="centered-order-stage-content large-small">
              <Grid container>
                {order.base_images.map((img) => (
                  <Grid xs={6} lg={3} key={img.url}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "0.5em",
                        marginBottom: "0.5em",
                      }}
                    >
                      <div>
                        <div
                          class="product-choice-outer-box mini"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setModalURL(img.url);
                          }}
                        >
                          <div class="product-choice-inner-box mini">
                            <img
                              src={img.url}
                              style={{
                                height: "inherit",
                                borderRadius: "25px",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        ></div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1em",
            }}
          >
            <LoadingButton
              size="small"
              startIcon={<ReceiptLongIcon />}
              loading={false}
              variant="contained"
              style={{
                backgroundColor: "white",
                color: "black",
                fontFamily: "Source Code Pro",
                borderRadius: "25px",
                marginTop: "15px",
                marginBottom: "2em",
                fontSize: "0.875rem",
                fontWeight: 600,
                padding: "10px 25px 10px 25px",
                border: "0px solid #ccc",
                height: "3.5em",
                minWidth: IsSmallSize() ? "7em" : "13em",
              }}
              onClick={() => {
                window.open(
                  order.fulfillment_info.receipt.receipt_url,
                  "_blank"
                );
              }}
            >
              Receipt
            </LoadingButton>
            <LoadingButton
              size="small"
              endIcon={<ShareLocationIcon />}
              disabled={trackingLinkReady === undefined}
              loading={false}
              variant="contained"
              style={{
                marginLeft: "10px",
                backgroundColor:
                  trackingLinkReady === undefined ? "#ccc" : "#2C3441",
                fontFamily: "Source Code Pro",
                borderRadius: "25px",
                marginTop: "15px",
                fontSize: "0.875rem",
                fontWeight: 600,
                padding: "10px 25px 10px 25px",
                height: "3.5em",
                minWidth: IsSmallSize() ? "7em" : "13em",
              }}
              onClick={() => {
                window.open(trackingLinkReady, "_blank");
              }}
            >
              {IsSmallSize() ? "Track" : "Track Order"}
            </LoadingButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function OrderToplevelDetails({ order, setModalURL }): JSX.Element {
  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span
          class="preview-order-name medium-text-align-center"
          style={{ fontFamily: "Poppins Medium", fontWeight: 700 }}
        >
          Order ID: {SanitizeOrderID(order.id)}
        </span>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span
          class="preview-order-name medium-text-align-center"
          style={{ fontFamily: "Poppins" }}
        >
          {order.product_info.name}
          <Tooltip title="Product Specifications">
            <InfoIcon
              style={{
                fontSize: "0.7em",
                marginLeft: "0.3em",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalURL(
                  ViewProductSpec(
                    order.product_info.id,
                    order.product_info.variant_id
                  )
                );
              }}
            />
          </Tooltip>
        </span>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span
          class="preview-order-name medium-text-align-center"
          style={{
            fontFamily: "Poppins Thin",
            fontSize: "1em",
            fontWeight: 600,
            color: "#5895FE",
          }}
        >
          {order?.fulfillment_info?.estimated_delivery
            ? order?.fulfillment_info?.estimated_delivery
            : "Working on it!"}
        </span>
      </div>
    </React.Fragment>
  );
}

function calculateTotalQuantity(order) {
  var count = 0;
  order.fulfillment_info.ordered.forEach((orderedItem) => {
    count += orderedItem.quantity;
  });
  return count;
}

function extractTrackingLink(order) {
  if (
    order?.fulfillment_info?.shipping_progress?.shipments &&
    order?.fulfillment_info?.shipping_progress?.shipments.length > 0
  ) {
    return order?.fulfillment_info?.shipping_progress?.shipments[0]
      .tracking_link;
  }

  return undefined;
}
