import * as React from "react";
import { Box, Button } from "@mui/material";
import Typewriter from "typewriter-effect/dist/core";
import Grid from "@mui/material/Grid";
import { IsSmallSize } from "../utils/size";
import { Footer } from "../global-components/Footer";
import { useAuth0 } from "@auth0/auth0-react";
import Tooltip from "@mui/material/Tooltip";

export function Home(): JSX.Element {
  let padding = "100px";
  if (IsSmallSize()) {
    padding = "10px";
  }

  return (
    <React.Fragment>
      <Box sx={{ padding: padding }} className="hero-image">
        <HomePageTop />
        <HomePageMiddle />
        <HomeBageBottom />
      </Box>
      <Footer />
    </React.Fragment>
  );
}

function HomePageMiddle(): JSX.Element {
  const content = [
    {
      classPart: "mug",
      urlPath:
        "/demo?stage=4&product=%257B%2522productID%2522%253A403%252C%2522variantID%2522%253A11051%257D&prompt=%255B%252215mm%2520wide-angle%2520lens%2520photo%2520of%2520a%2520rapper%2520in%25201990%2520New%2520York%2520holding%2520a%2520kitten%2520up%2520to%2520the%2520camera%2522%255D&image_id=%2522a62fca04-f8c6-45c3-9b29-b29da1879b80%2522",
      tooltip: "Ceramic Mug",
    },
    {
      classPart: "notebook",
      urlPath:
        "/demo?stage=4&product=%257B%2522productID%2522%253A474%252C%2522variantID%2522%253A12141%257D&prompt=%255B%2522Johnny%2520Bravo%2520if%2520he%2520was%2520a%2520real%2520person%252C%2520realistic%252C%2520studio%2520photo%2522%255D&image_id=%2522ed90e46d-bfeb-43e0-bb6c-a78f9aac3fdc%2522",
      tooltip: "140 Page Notebook",
    },
    {
      classPart: "card",
      urlPath:
        "/demo?stage=4&product=%257B%2522productID%2522%253A568%252C%2522variantID%2522%253A14457%257D&prompt=%255B%2522A%2520postal%2520worker%2520fighting%2520the%2520snow%2520blizzard%2520at%2520dusk%2520oil%2520on%2520canvas%2522%255D&image_id=%2522fd70d0ef-1b0f-4463-8e23-75e2ccb728fb%2522",
      tooltip: "Card",
    },
    {
      classPart: "coaster",
      urlPath:
        "/demo?stage=4&product=%257B%2522productID%2522%253A611%252C%2522variantID%2522%253A15662%257D&prompt=%255B%2522A%2520dog%2520riding%2520a%2520unicycle%2520in%2520downtown%2520tokyo%2520photo%2520realistic%2522%255D&image_id=%2522c6f89c96-bde8-493d-87d5-16414683e8b5%2522",
      tooltip: "Cork Coaster",
    },
    {
      classPart: "mask",
      urlPath:
        "/demo?stage=4&product=%257B%2522productID%2522%253A434%252C%2522variantID%2522%253A11520%257D&prompt=%255B%2522Mr.%2520Peanut%2520Butter%2520from%2520Bojack%2520Horseman%2520%282014%29%2520in%2520Spider-Man%253A%2520Into%2520the%2520Spider-Verse%2520%282018%29%2522%255D&image_id=%25229c4e35b4-e039-4fc9-90da-45184b817531%2522",
      tooltip: "Reusable Facemask",
    },
    {
      classPart: "sticker",
      urlPath:
        "/demo?stage=4&product=%257B%2522productID%2522%253A358%252C%2522variantID%2522%253A10164%257D&prompt=%255B%2522A%2520still%2520of%2520Bojack%2520Horseman%2520in%2520The%2520Grand%2520Budapest%2520Hotel%2520%282014%29%2522%255D&image_id=%2522765fc909-db13-4772-bca8-3cc454829267%2522",
      tooltip: "Sticker",
    },
    {
      classPart: "frame",
      urlPath:
        "/demo?stage=4&product=%257B%2522productID%2522%253A2%252C%2522variantID%2522%253A4652%257D&prompt=%255B%252215mm%2520wide-angle%2520lens%2520photo%2520of%2520a%2520rapper%2520in%25201990%2520New%2520York%2520holding%2520a%2520kitten%2520up%2520to%2520the%2520camera%2522%255D&image_id=%2522b37098d4-eae3-444b-9193-45f8370bba24%2522",
      tooltip: "Frame",
    },
  ];

  shuffle(content);

  return (
    <Box class="centered-full-stage-content">
      <h3 style={{ textAlign: "center" }}>
        Endless Possibilities for Unique Product Combinations
      </h3>
      <Box
        style={{
          marginTop: "1em",
          marginBottom: "1em",
          width: "100%",
          overflowY: "hidden",
          overflowX: "scroll",
          height: "12em",
        }}
      >
        <div style={{ width: "calc(100% + 10em)" }}>
          {content.map((c) => (
            <Tooltip title={c.tooltip}>
              <div
                style={{
                  marginLeft: "0.75em",
                  marginRight: "0.75em",
                  float: "left",
                }}
                onClick={() => {
                  window.location = c.urlPath;
                }}
              >
                <div
                  class="product-choice-outer-box small"
                  style={{ cursor: "pointer" }}
                >
                  <div class="product-choice-inner-box small">
                    <div class={"icon-creation " + c.classPart}></div>
                  </div>
                </div>
              </div>
            </Tooltip>
          ))}
        </div>
      </Box>
      <h3 style={{ textAlign: "center" }}>
        8 Products x Infinite Unique Images
      </h3>
    </Box>
  );
}

function HomePageTop(): JSX.Element {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const mainText = (
    <Grid
      className="home-page home-top-sec"
      container
      justifyContent="center"
      xs={12}
      md={12}
      style={{ display: "flex", alignItems: "center" }}
      spacing={2}
    >
      <Grid
        xs={12}
        md={6}
        style={{ height: "25em", width: "25em" }}
        className="hero-image-top"
      >
        <div className="nopadmob">
          <h1
            class="toptitle"
            style={{ fontSize: "2.5em", fontFamily: "Poppins Thin" }}
          >
            <span style={{ fontWeight: 800, fontFamily: "Poppins Medium" }}>
              Stand out with
            </span>{" "}
            unique,{" "}
            <span style={{ fontWeight: 800, fontFamily: "Poppins Medium" }}>
              AI generated merchandise
            </span>
            <br></br>
            <h3 style={{ fontSize: "0.6em" }}>
              Make it Real with DALL-E On-Demand Printing!
            </h3>
          </h1>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#2C3441",
              borderRadius: "25px",
            }}
            onClick={() => {
              if (isAuthenticated) {
                window.location = "/dashboard";
              } else {
                loginWithRedirect({ screen_hint: "signup" });
              }
            }}
          >
            GET STARTED NOW
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#2C3441",
              marginLeft: "10px",
              borderRadius: "25px",
            }}
            href="https://www.artgen.store/demo"
          >
            Demo
          </Button>
        </div>
      </Grid>
      <Grid
        xs={12}
        md={6}
        style={{ height: "25em", width: "25em", marginTop: "1em" }}
      >
        <div class="hero-image-right"></div>
      </Grid>
    </Grid>
  );

  return (
    <Grid
      className="home-page"
      container
      spacing={1}
      style={{ height: "100%", paddingTop: "1em", paddingBottom: "2em" }}
    >
      {mainText}
    </Grid>
  );
}

function HomeBageBottom(): JSX.Element {
  const fontSize = IsSmallSize() ? "1.3em" : "2.6em";
  const topRef = React.useRef(null);

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    const topTypewriter = new Typewriter(topRef.current, {
      loop: false,
    });

    topTypewriter
      .pauseFor(1500)
      .typeString(
        "Johnny Bravo if he was a real person, realistic, studio photo"
      )
      .start();
  }, []);

  return (
    <Box
      style={{
        width: "100%",
        height: "inherit",
        paddingBottom: "4em",
      }}
    >
      <Grid className="home-page" container spacing={1}>
        <Grid
          className="home-page"
          container
          xs={12}
          style={{ textAlign: "center", marginTop: "2em" }}
          justifyContent="center"
        >
          <h2
            class="mobiletitle"
            style={{
              color: "#2C3441",
              fontWeight: 800,
              fontSize: fontSize,
              fontFamily: "Poppins Medium",
            }}
          >
            Simple.{" "}
            <span style={{ fontWeight: 600, fontFamily: "Poppins Thin" }}>
              Powerful.
            </span>{" "}
            Unique.
          </h2>
        </Grid>
        <Grid
          className="home-page"
          container
          xs={12}
          style={{
            marginTop: "4em",
            display: "flex",
            alignItems: "center",
            textAlign: "left",
          }}
          justifyContent="center"
          spacing={2}
        >
          <Grid
            xs={12}
            md={6}
            style={{
              height: "15em",
              width: "15em",
              paddingLeft: "1em",
              paddingRight: "1em",
            }}
          >
            <div class="hero-step-1"></div>
          </Grid>
          <Grid
            xs={12}
            md={6}
            style={{ width: "15em", paddingLeft: "1em", paddingRight: "1em" }}
          >
            <div
              class="rightmobile"
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Poppins Medium",
                fontWeight: 700,
                marginTop: IsSmallSize() ? "2em" : "0px",
              }}
            >
              <span class="hero-step-1-number"></span>
              <span
                style={{
                  fontSize: "1.4em",
                  fontFamily: "Poppins Medium",
                  fontWeight: 700,
                }}
              >
                Provide Prompt
              </span>
            </div>
            <div
              class="rightmobile"
              style={{
                width: IsSmallSize() ? "100%" : "70%",
                marginTop: "0.5em",
              }}
            >
              Give DALL-E (an Artificial Intelligence program) four prompts and
              watch as it creates 16 one-of-a-kind images for you with each
              order! Experience the magic of AI-generated art in a whole new
              way.
            </div>
            <div
              style={{
                width: IsSmallSize() ? "100%" : "70%",
                marginTop: "1.75em",
              }}
            >
              <div class="quotation-mark-right"></div>
              <br></br>
              <span ref={topRef}></span>
            </div>
          </Grid>
        </Grid>
        <Grid
          className="home-page"
          container
          xs={12}
          style={{
            marginTop: "4em",
            display: "flex",
            alignItems: "center",
            textAlign: "left",
          }}
          justifyContent="center"
          spacing={2}
        >
          <Grid
            className="onlymob"
            xs={12}
            md={6}
            style={{
              height: "15em",
              width: "15em",
              paddingLeft: "1em",
              paddingRight: "1em",
            }}
          >
            <div class="hero-step-2"></div>
          </Grid>
          <Grid
            xs={12}
            md={6}
            style={{
              width: "15em",
              textAlign: "right",
              paddingLeft: "1em",
              paddingRight: "1em",
            }}
          >
            <div
              class="rightmobile"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "right",
                fontFamily: "Poppins Medium",
                fontWeight: 700,
                marginTop: IsSmallSize() ? "2em" : "0px",
              }}
            >
              <span class="hero-step-2-number"></span>
              <span
                style={{
                  fontSize: "1.4em",
                  fontFamily: "Poppins Medium",
                  fontWeight: 700,
                }}
              >
                Choose Image
              </span>
            </div>
            <div
              class="rightmobile"
              style={{
                width: IsSmallSize() ? "100%" : "70%",
                marginTop: "0.5em",
                float: "right",
              }}
            >
              Select the image that best resonates with you...
              <br></br>
              <br></br>
              ... Preview your product with ease using our real-time mockup
              feature, including stickers, mugs, cards, and more.
            </div>
          </Grid>
          <Grid
            className="onlydesk"
            xs={12}
            md={6}
            style={{
              height: "15em",
              width: "15em",
              paddingLeft: "1em",
              paddingRight: "1em",
            }}
          >
            <div class="hero-step-2"></div>
          </Grid>
        </Grid>
        <Grid
          className="home-page"
          container
          xs={12}
          style={{
            marginTop: "4em",
            display: "flex",
            alignItems: "center",
            textAlign: "left",
          }}
          justifyContent="center"
          spacing={2}
        >
          <Grid
            xs={12}
            md={6}
            style={{
              height: "15em",
              width: "15em",
              paddingLeft: "1em",
              paddingRight: "1em",
            }}
          >
            <div class="hero-step-3"></div>
          </Grid>
          <Grid
            xs={12}
            md={6}
            style={{ width: "15em", paddingLeft: "1em", paddingRight: "1em" }}
          >
            <div
              class="rightmobile"
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Poppins Medium",
                fontWeight: 700,
                marginTop: IsSmallSize() ? "2em" : "0px",
              }}
            >
              <span class="hero-step-3-number"></span>
              <span
                style={{
                  fontSize: "1.4em",
                  fontFamily: "Poppins Medium",
                  fontWeight: 700,
                }}
              >
                Place the Order
              </span>
            </div>
            <div
              class="rightmobile"
              style={{
                width: IsSmallSize() ? "100%" : "70%",
                marginTop: "0.5em",
              }}
            >
              Take it easy. Your order will be delivered right to your doorstep
              at no extra shipping cost.
              <br></br>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#2C3441",
                  marginTop: "15px",
                  borderRadius: "25px",
                }}
                onClick={() => {
                  if (isAuthenticated) {
                    window.location = "/dashboard";
                  } else {
                    loginWithRedirect({ screen_hint: "signup" });
                  }
                }}
              >
                Get Started
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
