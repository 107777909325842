import * as React from "react";
import { Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { GetUserMetadata } from "../utils/user";
import Grid from "@mui/material/Grid";
import Faq from "react-faq-component";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Tooltip from "@mui/material/Tooltip";
import { OrderProgressBar } from "../order/OrderProgressBar";
import { CreateOrder as CreateOrderAPICall } from "../utils/api";
import { GetIPLocation } from "../utils/location";
import LoadingButton from "@mui/lab/LoadingButton";
import { IsSmallSize } from "../utils/size";

export function CreateOrder(): JSX.Element {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [userWithCode, setUserWithCode] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    const getUserMetadata = async () => {
      const userObj = await GetUserMetadata(
        isAuthenticated,
        user,
        getAccessTokenSilently
      );
      if (userObj !== undefined) {
        setUserWithCode(userObj);
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub, isAuthenticated, user]);

  return (
    <Box className="hero-image" style={{ minHeight: "100vh" }}>
      <OrderProgressBar stage={0} />
      <div class="centered-full-stage-content">
        <Grid container>
          <Grid
            xs={12}
            md={7}
            style={{ padding: IsSmallSize() ? "0px" : "3em" }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ width: IsSmallSize() ? "90%" : "75%" }}>
                <span
                  style={{
                    fontFamily: "Poppins Thin",
                    display: IsSmallSize() ? "inline-block" : "",
                    fontSize: IsSmallSize() ? "2.5em" : "3em",
                  }}
                  class="medium-text-align-center"
                >
                  Unleash your creativity with{" "}
                  <span style={{ fontFamily: "Poppins", fontWeight: 700 }}>
                    AI-generated art
                  </span>
                </span>
                {faqQuestions.map((d) => (
                  <div
                    style={{
                      marginTop: "1em",
                      border: "1px solid #ccc",
                      borderRadius: "25px",
                      paddingLeft: "10px",
                      fontWeight: 600,
                    }}
                  >
                    <Faq
                      data={d}
                      styles={{
                        bgColor: "transparent",
                        titleTextSize: "1em",
                        rowTitleTextSize: "1em",
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={5}>
            <div
              style={{ display: "flex", justifyContent: "center" }}
              class="create-order-border"
            >
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div class="img-border large" style={{ marginTop: "3em" }}>
                    <div class="create-order-image large"></div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "1.5em",
                  }}
                >
                  <div>
                    <LoadingButton
                      size="small"
                      endIcon={<ArrowForwardIcon />}
                      loading={isLoading}
                      variant="contained"
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#2C3441",
                        fontFamily: "Source Code Pro",
                        borderRadius: "25px",
                        marginTop: "15px",
                        minWidth: "25em",
                        fontSize: "0.875rem",
                        fontWeight: 600,
                        padding: "10px",
                      }}
                      onClick={() => {
                        setIsLoading(true);
                        ClickedCreateOrderButton(userWithCode);
                      }}
                      disabled
                    >
                      PAY $1, CREATE AN ORDER
                    </LoadingButton>
                    <br></br>
                    <LoadingButton
                      size="small"
                      endIcon={<ArrowForwardIcon />}
                      loading={false}
                      loadingPosition="end"
                      variant="contained"
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "#FFFFFF",
                        fontFamily: "Source Code Pro",
                        borderRadius: "25px",
                        marginTop: "15px",
                        minWidth: "25em",
                        fontSize: "0.875rem",
                        fontWeight: 600,
                        color: "black",
                        padding: "10px",
                      }}
                      onClick={() => {
                        window.location = "/demo";
                      }}
                    >
                      TRY A DEMO WITHOUT PAY&nbsp;
                    </LoadingButton>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}

const faqQuestions = [
  {
    title: "",
    rows: [
      {
        title: "Why is there a fee to create an order?",
        content: (
          <React.Fragment>
            <p>
              We charge a fee to create an order to cover the costs of
              generating art using DALLE, processing orders, and maintaining the
              website. These costs include computing power, time, team
              coordination, server expenses, hosting fees, domain registration
              and security measures. We strive to provide high-quality services
              and appreciate your understanding and support.
            </p>
          </React.Fragment>
        ),
      },
    ],
  },
  {
    title: "",
    rows: [
      {
        title: "How many images do I get per order?",
        content: (
          <React.Fragment>
            <p>
              For a single order, you get 16 unique images generated using the
              DALLE AI Model (
              <a
                href="https://openai.com/dall-e-2/"
                target="_blank"
                rel="noreferrer"
              >
                https://openai.com/dall-e-2/
              </a>
              ). These images are created in response to 4 different prompts,
              resulting in 4 images for each prompt.
            </p>
          </React.Fragment>
        ),
      },
    ],
  },
  {
    title: "",
    rows: [
      {
        title: "What does the finished product look like?",
        content: (
          <React.Fragment>
            <p>
              The finished product depends on what product, and image you
              choose. No two orders with different image prompts will be the
              same.
            </p>
            <p>
              Here are a few examples of what the finished products look like
              (these are mockups):
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Tooltip title="Greeting Card. Prompt 'A postal worker fighting the snow blizzard at dusk oil on canvas'">
                <Box>
                  <div class="create-order-example greeting-card-example"></div>
                </Box>
              </Tooltip>
              <Tooltip title="Sticker. Prompt 'A dog riding a unicycle in downtown tokyo photo realistic'">
                <Box>
                  <div class="create-order-example sticker-example"></div>
                </Box>
              </Tooltip>
            </div>
          </React.Fragment>
        ),
      },
    ],
  },
  {
    title: "",
    rows: [
      {
        title: "What happens if I decide not to buy a product?",
        content: (
          <p>
            If you don't complete an order, you will not be charged for any
            additional amounts beyond the non-refundable service fee. This is
            because the service fee is used to cover the cost of processing the
            order and providing the service.
          </p>
        ),
      },
    ],
  },
  {
    title: "",
    rows: [
      {
        title: "What are the forms of payment?",
        content: (
          <React.Fragment>
            <p>
              We use{" "}
              <a href="https://stripe.com/" target="_blank" rel="noreferrer">
                Stripe
              </a>
              , a secure and reliable payment processing platform, to process
              all our payments. Stripe accepts credit and debit cards, mobile
              payments (Apple Pay, Google Pay, etc.), ACH, SEPA, Bancontact and
              Alipay/WeChat Pay and supports over 135 currencies.
            </p>
          </React.Fragment>
        ),
      },
    ],
  },
];

async function ClickedCreateOrderButton(user) {
  const approximateLocation = await GetIPLocation();

  const orderInfo = await CreateOrderAPICall(user, approximateLocation);
  if (!orderInfo) {
    console.log("Failed to create order");
    return;
  }

  window.location = orderInfo.payment.checkout_url;
}
