import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { AppToolbar } from "./global-components/AppToolbar";
import { BaseURL, AuthDomain, AuthClientID } from "./utils/env";
import { ErrorBoundary } from "./global-components/ErrorBoundary";
import { SuperSEO } from "react-super-seo";
import { ArtGenRoutes } from "./Routes";

function App() {
  return (
    <ErrorBoundary>
      <SuperSEO
        title="ArtGen - AI Generated Merchandise"
        description="Get Unique AI-Designed Merchandise! T-Shirts, Stickers & More. Shop Now! Powered by Dalle and OpenAI"
        lang="en"
        openGraph={{
          ogImage: {
            ogImage:
              "https://d124iw7xdacocx.cloudfront.net/static/artgen-logo-image-512x512.png",
            ogImageAlt: "ArtGen - AI Merch",
            ogImageWidth: 512,
            ogImageHeight: 512,
            ogImageType: "image/png",
          },
        }}
        twitter={{
          twitterSummaryCard: {
            summaryCardImage:
              "https://d124iw7xdacocx.cloudfront.net/static/artgen-logo-image-512x512.png",
            summaryCardImageAlt: "ArtGen - AI Merch",
          },
        }}
      >
        <meta
          name="keywords"
          content="Dalle, AI, Art, Merch, Swag, Sticker, Mug, T-Shirts"
        />
      </SuperSEO>
      <Auth0Provider
        domain={AuthDomain()}
        clientId={AuthClientID()}
        audience="artgen-be-srv"
        redirectUri={`${BaseURL()}/dashboard`}
        useRefreshTokens
        cacheLocation="localstorage"
      >
        <Router>
          <div style={{ minHeight: "100vh" }}>
            <AppToolbar />
            <ArtGenRoutes />
          </div>
        </Router>
      </Auth0Provider>
    </ErrorBoundary>
  );
}

export default App;
