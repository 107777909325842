import { GetIPLocation } from "./location";

export function UpdateArrQueryParam(key, val) {
  var searchParams = new URLSearchParams(window.location.search);
  let initialList = searchParams.get(key);
  if (initialList === null) {
    initialList = [];
  } else {
    initialList = JSON.parse(decodeURIComponent(initialList));
  }

  initialList.push(val);

  WriteQueryParam(key, initialList);
}

export function WriteQueryParam(key, val) {
  var searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, encodeURIComponent(JSON.stringify(val)));
  let newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?" +
    searchParams.toString();
  window.history.pushState({ path: newurl }, "", newurl);
}

export function ParseDemoStageFromQueryParams() {
  var searchParams = new URLSearchParams(window.location.search);

  const orderID = searchParams.get("order_id");
  if (orderID !== null) {
    return 5;
  }

  const stage = searchParams.get("stage");
  if (stage !== null) {
    return parseInt(stage);
  }

  return 1;
}

function getPreviousOrderLocalStorageKey(params) {
  let chosenCountryCode = params.approximate_location.country_code;
  if (params.country_override) {
    chosenCountryCode = params.country_override;
  }

  return (
    "demo-order" +
    params.chosen_product.product_id +
    ":" +
    params.chosen_product.variant_id +
    ":" +
    params.chosen_image +
    ":" +
    chosenCountryCode
  );
}

export async function WritePreviousOrderToLocalStorage(params, orderID) {
  if (
    !params ||
    (params &&
      (!params.chosen_product ||
        !params.chosen_image ||
        !params.approximate_location))
  ) {
    return;
  }

  const key = getPreviousOrderLocalStorageKey(params);
  localStorage.setItem(key, orderID);
}

export async function ParsePreviousDemoOrderFromDemoParams(params) {
  let resp = undefined;
  if (
    !params ||
    (params &&
      (!params.chosen_product ||
        !params.chosen_image ||
        !params.approximate_location))
  ) {
    return undefined;
  }

  const key = getPreviousOrderLocalStorageKey(params);
  const previousOrder = localStorage.getItem(key);
  if (previousOrder) {
    return previousOrder;
  }

  return undefined;
}

export async function ParseDemoParamsFromQueryParams() {
  let resp = undefined;
  var searchParams = new URLSearchParams(window.location.search);

  const product = searchParams.get("product");
  if (product) {
    if (resp === undefined) {
      resp = {};
    }

    const productDecoded = JSON.parse(decodeURIComponent(product));
    resp.chosen_product = {
      product_id: productDecoded.productID,
      variant_id: productDecoded.variantID,
    };
  }

  const prompt = searchParams.get("prompt");
  if (prompt) {
    if (resp === undefined) {
      resp = {};
    }

    resp.chosen_prompts = JSON.parse(decodeURIComponent(prompt));
  }

  const imageID = searchParams.get("image_id");
  if (imageID) {
    if (resp === undefined) {
      resp = {};
    }

    resp.chosen_image = JSON.parse(decodeURIComponent(imageID));
  }

  const stage = searchParams.get("stage");
  if (stage && parseInt(stage) === 4) {
    if (resp === undefined) {
      resp = {};
    }

    resp.approximate_location = await GetIPLocation();
  }

  const countryCode = searchParams.get("country");
  if (countryCode) {
    if (resp === undefined) {
      resp = {};
    }

    resp.country_override = JSON.parse(decodeURIComponent(countryCode));
  }

  const orderID = searchParams.get("order_id");
  if (orderID) {
    if (resp === undefined) {
      resp = {};
    }

    resp.order_id = orderID;
  }

  return resp;
}
