import * as React from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Grid";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import IconButton from "@mui/material/IconButton";
import { useAuth0 } from "@auth0/auth0-react";

export function Footer(): JSX.Element {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <Box class="footer-box">
      <Grid
        container
        spacing={4}
        style={{ paddingBottom: "6em", paddingTop: "2em" }}
      >
        <Grid
          xs={12}
          md={3}
          style={{
            color: "#FFFFFF",
            textAlign: "center",
            fontFamily: "Inter Light",
            paddingBottom: "1em",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#FFFFFF",
              borderRadius: "25px",
              color: "#2C3441",
            }}
            onClick={() => {
              if (isAuthenticated) {
                window.location = "/dashboard";
              } else {
                loginWithRedirect({ screen_hint: "signup" });
              }
            }}
          >
            Get Started
          </Button>
        </Grid>
        <Grid
          xs={12}
          md={3}
          style={{
            color: "#FFFFFF",
            textAlign: "center",
            fontFamily: "Inter Light",
            paddingBottom: "1em",
          }}
        >
          <strong>Account</strong>
          <br></br>
          <br></br>
          <Link
            underline="none"
            color="#FFFFFF"
            style={{
              fontWeight: 500,
              fontFamily: "Inter Light",
              cursor: "pointer",
            }}
            onClick={() => loginWithRedirect()}
          >
            Login
          </Link>
          <br></br>
          <br></br>
          <Link
            underline="none"
            color="#FFFFFF"
            style={{
              fontWeight: 500,
              fontFamily: "Inter Light",
              cursor: "pointer",
            }}
            onClick={() => loginWithRedirect({ screen_hint: "signup" })}
          >
            Register
          </Link>
        </Grid>
        <Grid
          xs={12}
          md={3}
          style={{
            color: "#FFFFFF",
            textAlign: "center",
            fontFamily: "Inter Light",
            paddingBottom: "1em",
          }}
        >
          <strong>Support</strong>
          <br></br>
          <br></br>
          support@artgen.store
          <br></br>
          <br></br>
          <a
            href="https://www.artgen.store/help-center"
            style={{
              color: "white",
              textDecoration: "none",
              fontFamily: "Inter Light",
            }}
          >
            Help Center
          </a>
        </Grid>
        <Grid
          xs={12}
          md={3}
          style={{
            color: "#FFFFFF",
            textAlign: "center",
            fontFamily: "Inter Light",
          }}
        >
          <strong>Follow Us</strong>
          <br></br>
          <br></br>
          <IconButton
            color="primary"
            onClick={() => {
              window.open(
                "https://www.youtube.com/channel/UC5EG5FNPUYFbm-675r72cmA",
                "_blank"
              );
            }}
          >
            <YouTubeIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid
          xs={12}
          md={4}
          style={{
            color: "#FFFFFF",
            textAlign: "center",
            fontFamily: "Inter Light",
          }}
        >
          Art Gen © Copyright 2023
        </Grid>
        <Grid
          xs={12}
          md={4}
          style={{
            color: "#FFFFFF",
            textAlign: "center",
            fontFamily: "Inter Light",
            fontSize: "2em",
            cursor: "pointer",
          }}
          onClick={() => {
            if (isAuthenticated) {
              window.location = "/dashboard";
            } else {
              window.location = "/";
            }
          }}
        >
          <div class="footer-logo"></div>
        </Grid>
        <Grid
          xs={12}
          md={4}
          style={{
            color: "#FFFFFF",
            textAlign: "center",
            fontFamily: "Inter Light",
          }}
        >
          <Link
            underline="none"
            color="#FFFFFF"
            style={{
              fontWeight: 500,
              fontFamily: "Inter Light",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location = "/terms-of-use";
            }}
          >
            Privacy Policy | Terms of Service
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}
