import * as React from "react";
import { Box } from "@mui/material";
import Faq from "react-faq-component";

export function TermsOfUse(): JSX.Element {
  return (
    <React.Fragment>
      <Box className="hero-image" style={{ minHeight: "100vh" }}>
        <div
          style={{
            minHeight: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <h2 style={{ textAlign: "center", marginBottom: "2em" }}>
              By signing up and using our service, you agree to the following
            </h2>
            <Box
              class="centered-order-stage-content large-small"
              style={{
                backgroundColor: "rgb(29, 26, 39, 0.9)",
                padding: "1em",
                borderRadius: 25,
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "2000px",
              }}
            >
              <Faq
                data={data}
                styles={{
                  bgColor: "transparent",
                  titleTextSize: "1em",
                  rowTitleTextSize: "1em",
                  titleTextColor: "white",
                  rowTitleColor: "white",
                  rowContentColor: "white",
                  arrowColor: "white",
                }}
              />
            </Box>
            <Box
              class="centered-order-stage-content large-small"
              style={{
                backgroundColor: "rgb(29, 26, 39, 0.9)",
                padding: "1em",
                borderRadius: 25,
                marginTop: "2em",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "2000px",
              }}
            >
              <Faq
                data={data2}
                styles={{
                  bgColor: "transparent",
                  titleTextSize: "1em",
                  rowTitleTextSize: "1em",
                  titleTextColor: "white",
                  rowTitleColor: "white",
                  rowContentColor: "white",
                  arrowColor: "white",
                }}
              />
            </Box>
            <Box
              class="centered-order-stage-content large-small"
              style={{
                backgroundColor: "rgb(29, 26, 39, 0.9)",
                padding: "1em",
                borderRadius: 25,
                marginTop: "2em",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "2000px",
              }}
            >
              <Faq
                data={data3}
                styles={{
                  bgColor: "transparent",
                  titleTextSize: "1em",
                  rowTitleTextSize: "1em",
                  titleTextColor: "white",
                  rowTitleColor: "white",
                  rowContentColor: "white",
                  arrowColor: "white",
                }}
              />
            </Box>
          </div>
        </div>
      </Box>
    </React.Fragment>
  );
}

const data = {
  rows: [
    {
      title: "Privacy Policy",
      content: (
        <React.Fragment>
          <h1 style={{ textAlign: "center" }}>PRIVACY POLICY</h1>
          <p>
            This Privacy Policy ("Policy") explains how ArtGen ("Company," "we,"
            "us," or "our") collects, uses, and shares information about you
            when you use our website and related services (the "Services").
          </p>
          <p>
            By using the Services, you agree to the collection, use, and sharing
            of your information as described in this Policy. If you do not agree
            with our policies and practices, do not use the Services.
          </p>
          <p>
            We may change our policies and practices from time to time, and we
            encourage you to review this Policy whenever you access the Services
            to stay informed about our information practices and the choices
            available to you.
          </p>
          <h2 style={{ textAlign: "center" }}>COLLECTION OF INFORMATION</h2>
          <p>We collect information about you in the following ways:</p>
          <ul>
            <li>
              Information you provide to us: We collect information that you
              provide to us directly, such as when you create an account, make a
              purchase, or communicate with us. This information may include
              your name, email address, and payment information.
            </li>
            <li>
              Information we collect automatically: We automatically collect
              information about you when you use the Services, such as your IP
              address, device type, and browser type.
            </li>
          </ul>
          <h2 style={{ textAlign: "center" }}>USE OF INFORMATION</h2>
          <p>
            We may use the information we collect about you for the following
            purposes:
          </p>
          <ul>
            <li>
              To provide the Services: We may use your information to provide
              the products or services you have requested, such as generating
              images onto configurable merchandise using DALLE.
            </li>
            <li>
              For marketing purposes: We may use your information to send you
              promotional emails or advertisements.
            </li>
          </ul>
          <h2 style={{ textAlign: "center" }}>SHARING OF INFORMATION</h2>
          <p>We may share your information with the following third parties:</p>
          <ul>
            <li>
              Service providers: We may share your information with third
              parties that provide services on our behalf, such as fulfillment
              services to ship your merchandise.
            </li>
            <li>
              Marketing partners: We may share your information with third
              parties for marketing purposes, such as sending you promotional
              emails or advertisements.
            </li>
          </ul>
          <p>We will not sell your personal information to third parties.</p>
          <h2 style={{ textAlign: "center" }}>SECURITY OF INFORMATION</h2>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access or disclosure. However, no method of
            transmission over the Internet, or method of electronic storage, is
            completely secure. Therefore, we cannot guarantee the absolute
            security of your personal information.
          </p>
          <h2 style={{ textAlign: "center" }}>CHANGES TO THIS POLICY</h2>
          <p>
            We may update this Policy from time to time to reflect changes to
            our practices or for other operational, legal, or regulatory
            reasons. We encourage you to review the Policy whenever you access
            the Services to stay informed about our information practices and
            the choices available to you.
          </p>
          <h2 style={{ textAlign: "center" }}>CONTACT US</h2>
          <p>
            If you have any questions or concerns about this Policy, please
            contact us at support@artgen.store.
          </p>
          <p>This Policy was last updated on December 20th, 2022.</p>
        </React.Fragment>
      ),
    },
  ],
};

const data2 = {
  rows: [
    {
      title: "Terms of Service",
      content: (
        <React.Fragment>
          <h1 style={{ textAlign: "center" }}>Terms of Service</h1>
          <p>
            Welcome to ArtGen, a service provided by ArtGen (“we”, “us”, or
            “our”). By accessing or using our website at artgen.store and any
            related services, applications, or tools (collectively, the
            "Service"), you are agreeing to be bound by these Terms of Service,
            all applicable laws and regulations, and agree that you are
            responsible for compliance with any applicable local laws. If you do
            not agree with any of these terms, you are prohibited from using or
            accessing the Service. The materials contained in the Service are
            protected by applicable copyright and trademark law.
          </p>
          <h2 style={{ textAlign: "center" }}>Use License</h2>
          <p>
            Permission is granted to temporarily download one copy of the
            materials (information or software) on ArtGen's website for
            personal, non-commercial transitory viewing only. This is the grant
            of a license, not a transfer of title, and under this license you
            may not:
          </p>
          <ul>
            <li>modify or copy the materials;</li>
            <li>
              use the materials for any commercial purpose, or for any public
              display (commercial or non-commercial);
            </li>
            <li>
              attempt to decompile or reverse engineer any software contained on
              ArtGen's website;
            </li>
            <li>
              remove any copyright or other proprietary notations from the
              materials; or
            </li>
            <li>
              transfer the materials to another person or "mirror" the materials
              on any other server.
            </li>
          </ul>
          <p>
            This license shall automatically terminate if you violate any of
            these restrictions and may be terminated by ArtGen at any time. Upon
            terminating your viewing of these materials or upon the termination
            of this license, you must destroy any downloaded materials in your
            possession whether in electronic or printed format.
          </p>
          <h2 style={{ textAlign: "center" }}>Disclaimer</h2>
          <p>
            The materials on ArtGen's website are provided on an 'as is' basis.
            ArtGen makes no warranties, expressed or implied, and hereby
            disclaims and negates all other warranties including, without
            limitation, implied warranties or conditions of merchantability,
            fitness for a particular purpose, or non-infringement of
            intellectual property or other violation of rights.
          </p>
          <p>
            Further, ArtGen does not warrant or make any representations
            concerning the accuracy, likely results, or reliability of the use
            of the materials on its website or otherwise relating to such
            materials or on any sites linked to this site.
          </p>
          <h2 style={{ textAlign: "center" }}>Limitations</h2>
          <p>
            In no event shall ArtGen or its suppliers be liable for any damages
            (including, without limitation, damages for loss of data or profit,
            or due to business interruption) arising out of the use or inability
            to use the materials on ArtGen's website, even if ArtGen or a ArtGen
            authorized representative has been notified orally or in writing of
            the possibility of such damage. Because some jurisdictions do not
            allow limitations on implied warranties, or limitations of liability
            for consequential or incidental damages, these limitations may not
            apply to you.
          </p>
          <h2 style={{ textAlign: "center" }}>Accuracy of materials</h2>
          <p>
            The materials appearing on ArtGen's website could include technical,
            typographical, or photographic errors. ArtGen does not warrant that
            any of the materials on its website are accurate, complete or
            current. ArtGen may make changes to the materials contained on its
            website at any time without notice. However, ArtGen does not make
            any commitment to update the materials.
          </p>
          <h2 style={{ textAlign: "center" }}>Links</h2>
          <p>
            ArtGen has not reviewed all of the sites linked to its website and
            is not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement by ArtGen of the
            site. Use of any such linked website is at the user's own risk.
          </p>
          <h2 style={{ textAlign: "center" }}>Modifications</h2>
          <p>
            ArtGen may revise these terms of service for its website at any time
            without notice. By using this website you are agreeing to be bound
            by the then current version of these terms of service.
          </p>
          <h2 style={{ textAlign: "center" }}>Governing Law</h2>
          <p>
            These terms and conditions are governed by and construed in
            accordance with the laws of Canada and you irrevocably submit to the
            exclusive jurisdiction of the courts in that State or location.
          </p>
          <h2 style={{ textAlign: "center" }}>Intellectual Property</h2>
          <p>
            The Service and its original content, features and functionality are
            and will remain the exclusive property of ArtGen and its licensors.
            The Service is protected by copyright, trademark, and other laws of
            both the United States and foreign countries. Our trademarks and
            trade dress may not be used in connection with any product or
            service without the prior written consent of ArtGen.
          </p>
          <h2 style={{ textAlign: "center" }}>Prohibited Uses</h2>
          <p>
            You may use the Service only for lawful purposes and in accordance
            with these Terms of Service. You agree not to use the Service:
          </p>
          <ul>
            <li>
              In any way that violates any applicable national or international
              law or regulation.
            </li>
            <li>
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content or otherwise.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material, including any "junk mail," "chain letter,"
              "spam," or any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate ArtGen, a ArtGen
              employee, another user, or any other person or entity.
            </li>
            <li>
              In any way that infringes upon the rights of others, or in any way
              is illegal, threatening, fraudulent, or harmful, or in connection
              with any unlawful, illegal, fraudulent, or harmful purpose or
              activity.
            </li>
          </ul>
          <p>You further agree not to use the Service to:</p>
          <ul>
            <li>
              Upload, post, email, transmit, or otherwise make available any
              content that you do not have a right to make available under any
              law or under contractual or fiduciary relationships (such as
              inside information, proprietary and confidential information
              learned or disclosed as part of employment relationships or under
              nondisclosure agreements).
            </li>
            <li>
              Upload, post, email, transmit, or otherwise make available any
              content that infringes any patent, trademark, trade secret,
              copyright, or other proprietary rights of any party.
            </li>
            <li>
              Upload, post, email, transmit, or otherwise make available any
              unsolicited or unauthorized advertising, promotional materials,
              "junk mail," "spam," "chain letters," "pyramid schemes," or any
              other form of solicitation.
            </li>
            <li>
              Upload, post, email, transmit, or otherwise make available any
              material that contains software viruses or any other computer
              code, files, or programs designed to interrupt, destroy, or limit
              the functionality of any computer software or hardware or
              telecommunications equipment.
            </li>
            <li>
              Interfere with or disrupt the Service or servers or networks
              connected to the Service, or violate any requirements, procedures,
              policies, or regulations of networks connected to the Service.
            </li>
            <li>
              Intentionally or unintentionally violate any applicable local,
              state, national, or international law, including, but not limited
              to, regulations promulgated by the U.S. Securities and Exchange
              Commission, any rules of any national or other securities
              exchange, and any regulations having the force of law.
            </li>
            <li>"Stalk" or otherwise harass another.</li>
            <li>Collect or store personal data about other users.</li>
          </ul>
          <h2 style={{ textAlign: "center" }}>User Content</h2>
          <p>
            You are responsible for your own content and the consequences of
            posting or publishing it. By uploading, posting, or otherwise making
            available any content on or through the Service, you represent and
            warrant that:
          </p>
          <ul>
            <li>
              You have the right to do so and have the necessary licenses and
              permissions.
            </li>
            <li>
              The content does not infringe the intellectual property rights or
              any other rights of any third party.
            </li>
            <li>
              The content complies with these Terms of Service and all
              applicable laws.
            </li>
          </ul>
          <p>
            You retain all of your ownership rights in your content. However, by
            uploading, posting, or otherwise making available any content on or
            through the Service, you grant us a worldwide, perpetual,
            irrevocable, royalty-free, transferable, sublicensable right and
            license to use, reproduce, modify, adapt, publish, translate, create
            derivative works from, distribute, perform, and display such content
            (in whole or part) and to incorporate it into other works in any
            format or medium now known or later developed. You waive any moral
            rights you may have in your content.
          </p>
          <p>
            We have the right, but not the obligation, to monitor, edit, or
            remove any content that we determine in our sole discretion is
            unlawful, offensive, threatening, libelous, defamatory,
            pornographic, obscene, or otherwise objectionable or violates any
            party's intellectual property or these Terms of Service.
          </p>
          <h2 style={{ textAlign: "center" }}>Termination</h2>
          <p>
            We reserve the right to terminate your access to the Service,
            without notice, for any conduct that we, in our sole discretion,
            believe is in violation of any applicable law or is harmful to the
            interests of another user, a third-party provider, a service
            provider, or us. In the event of termination, you will no longer be
            authorized to access the Service.
          </p>
          <h2 style={{ textAlign: "center" }}>Indemnification</h2>
          <p>
            You agree to indemnify, defend, and hold harmless ArtGen and its
            affiliates, officers, agents, and employees from any claim or
            demand, including reasonable attorneys' fees, made by any third
            party due to or arising out of your use of the Service, your
            violation of these Terms of Service, or your violation of any rights
            of another.
          </p>
          <h2 style={{ textAlign: "center" }}>Limitation of Liability</h2>
          <p>
            In no event shall ArtGen, nor its directors, employees, partners,
            agents, suppliers, or affiliates, be liable for any indirect,
            incidental, special, consequential, or punitive damages, including
            without limitation, loss of profits, data, use, goodwill, or other
            intangible losses, resulting from (i) your access to or use of or
            inability to access or use the Service; (ii) any conduct or content
            of any third party on the Service; (iii) any content obtained from
            the Service; and (iv) unauthorized access, use, or alteration of
            your transmissions or content, whether based on warranty, contract,
            tort (including negligence) or any other legal theory, whether or
            not we have been informed of the possibility of such damage, and
            even if a remedy set forth herein is found to have failed of its
            essential purpose.
          </p>
          <h2 style={{ textAlign: "center" }}>Exclusions</h2>
          <p>
            Some jurisdictions do not allow the exclusion of certain warranties
            or the exclusion or limitation of liability for consequential or
            incidental damages, so the limitations above may not apply to you.
          </p>
          <h2 style={{ textAlign: "center" }}>Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of Canada, without regard to its conflict of law provisions.
          </p>
          <p>
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service, and supersede and replace any prior agreements we might
            have had between us regarding the Service.
          </p>
          <h2 style={{ textAlign: "center" }}>Changes</h2>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms of Service at any time. If a revision is material, we
            will try to provide at least 30 days' notice prior to any new terms
            taking effect. What constitutes a material change will be determined
            at our sole discretion.
          </p>
          <p>
            By continuing to access or use our Service after those revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, you are no longer authorized to
            access the Service.
          </p>
          <h2 style={{ textAlign: "center" }}>Contact Us</h2>
          <p>
            If you have any questions about these Terms of Service, please
            contact us at support@artgen.store.
          </p>
        </React.Fragment>
      ),
    },
  ],
};

const data3 = {
  rows: [
    {
      title: "Dalle Terms of Use",
      content: (
        <React.Fragment>
          <h1 style={{ textAlign: "center" }}>
            Please read through their terms of use, which also apply
          </h1>
          <p style={{ textAlign: "center" }}>
            <a
              href="https://openai.com/terms/"
              target="_blank"
              style={{ color: "white" }}
            >
              https://openai.com/terms/
            </a>
          </p>
        </React.Fragment>
      ),
    },
  ],
};
