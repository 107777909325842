import * as React from "react";
import { OrderProgressBar } from "../order/OrderProgressBar";
import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ChooseProductStage } from "../order/stages/ChooseProductStage";
import { MakeProcessingFeePaymentV2 } from "../order/stages/MakeProcessingFeePaymentStage";
import { ChoosePromptStage } from "../order/stages/ChoosePromptStage";
import { ChooseImageStage } from "../order/stages/ChooseImageStage";
import { ConfigureProductStage } from "../order/stages/ConfigureProductStage";
import {
  PreviewOrderStage,
  ChooseNewCountryValue,
} from "../order/stages/PreviewOrderStage";
import { ModalStyles } from "../utils/modal";
import Modal from "@mui/material/Modal";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { GetUserMetadata } from "../utils/user";
import {
  GetOrder,
  CreateBaseImages,
  ChooseOrderImage,
  ConfigureOrderProduct,
  ChooseOrderProduct,
  GetDemoContent,
} from "../utils/api";
import {
  ParseDemoStageFromQueryParams,
  ParseDemoParamsFromQueryParams,
} from "../utils/demo";
import { ChooseOrderCountry } from "../order/ChooseOrderCountry";
import { OrderStepper } from "../order/OrderStepper";
import CircularProgress from "@mui/material/CircularProgress";
import { ViewProductSpecs } from "../order/ViewProductSpecs";
import { IsViewProductSpec } from "../utils/products";

export function OrderPage({ demo }): JSX.Element {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  let { id } = useParams();

  const [modalURL, setModalURL] = React.useState("");
  const [order, setOrder] = React.useState(undefined);
  const [demoContent, setDemoContent] = React.useState([]);
  const [userWithCode, setUserWithCode] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const getUserMetadata = async () => {
      const userObj = await GetUserMetadata(
        isAuthenticated,
        user,
        getAccessTokenSilently
      );
      if (userObj !== undefined) {
        const order = await GetOrder(userObj, id);

        setOrder(order);
        setUserWithCode(userObj);
        setIsLoading(false);
      }
    };

    const getDemoStage = async () => {
      const demoParams = await ParseDemoParamsFromQueryParams();
      const demoContent = await GetDemoContent(
        ParseDemoStageFromQueryParams(),
        demoParams
      );
      setOrder(demoContent.order);
      setDemoContent(demoContent.prompts);
      setIsLoading(false);

      const userObj = await GetUserMetadata(
        isAuthenticated,
        user,
        getAccessTokenSilently
      );
      if (userObj !== undefined) {
        setUserWithCode(userObj);
      }
    };

    if (demo === true) {
      getDemoStage();
    } else {
      getUserMetadata();
    }
  }, [getAccessTokenSilently, user?.sub, isAuthenticated, user, id]);

  return (
    <React.Fragment>
      <Box className="hero-image">
        {isLoading ? (
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <OrderStepper
            user={userWithCode}
            order={order}
            setOrder={setOrder}
            setModalURL={setModalURL}
            demo={demo}
            demoPrompts={demoContent}
          />
        )}
      </Box>
      <Modal
        open={modalURL !== ""}
        onClose={() => {
          setModalURL("");
        }}
        style={{ overflow: "scroll" }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyles}>
          {modalURL === ChooseNewCountryValue ? (
            <ChooseOrderCountry
              currentCountryCode={order?.country_code}
              setOrder={setOrder}
              user={userWithCode}
              order={order}
              orderID={id}
              setModalURL={setModalURL}
              demo={demo}
            />
          ) : (
            <React.Fragment>
              {IsViewProductSpec(modalURL) ? (
                <ViewProductSpecs modalURL={modalURL} />
              ) : (
                <img
                  src={modalURL}
                  height="100%"
                  width="100%"
                  style={{ borderRadius: "7px" }}
                ></img>
              )}
            </React.Fragment>
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
}
