import * as React from "react";
import { Box, Button } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export function ConfigureProductStage({
  order,
  handleStageButton,
}): JSX.Element {
  const [value, setValue] = React.useState("");

  const inputTextOnChange = function (input) {
    setValue(input.target.value);
  };

  let topText = "Configure";
  let topTextTwo = "your";
  let topTextThree = "product.";
  let buttonText = "SAVE CONFIGURATION";
  let handleClickFn = () => {
    handleStageButton({});
  };
  if (order && order.product_info && order.product_info.id > 0) {
    switch (order.product_info.id) {
      case 568:
        topText = "Customize the";
        topTextTwo = "inner";
        topTextThree = "text in your card!";
        buttonText = "SAVE INNER CARD TEXT";
        handleClickFn = () => {
          handleStageButton({ greeting_card_inner: value });
        };
        break;
    }
  }

  return (
    <React.Fragment>
      <Box class="order-stage-box">
        <div>
          <div class="choose-product-outer-circle">
            <div class="choose-product-icon-inner-circle">
              <TuneIcon style={{ color: "white", fontSize: "2em" }} />
            </div>
          </div>
        </div>
      </Box>
      <Box class="order-stage-box" style={{ marginTop: "0px" }}>
        <h1 style={{ fontFamily: "Poppins Medium" }}>
          {topText}{" "}
          <span style={{ fontFamily: "Poppins Thin" }}>{topTextTwo}</span>{" "}
          {topTextThree}
        </h1>
      </Box>
      <Box
        style={{ display: "flex", justifyContent: "center", marginTop: "1em" }}
      >
        <TextField
          label="Enter Card Text"
          focused
          style={{ width: "50%" }}
          value={value}
          onChange={inputTextOnChange}
        />
      </Box>
      <Box
        style={{ display: "flex", justifyContent: "center", marginTop: "1em" }}
      >
        <span style={{ fontStyle: "italic" }}>
          You don't need to include any text if you don't want.
        </span>
      </Box>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1em" }}
      >
        <LoadingButton
          size="small"
          endIcon={<ArrowForwardIcon />}
          loading={false}
          variant="contained"
          style={{
            marginLeft: "10px",
            backgroundColor: "#2C3441",
            fontFamily: "Source Code Pro",
            borderRadius: "25px",
            marginTop: "15px",
            fontSize: "0.875rem",
            fontWeight: 600,
            padding: "10px 25px 10px 25px",
          }}
          onClick={() => {
            handleClickFn();
          }}
        >
          {buttonText}
        </LoadingButton>
      </div>
    </React.Fragment>
  );
}
