import * as React from "react";
import { Box } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from "@mui/material/CircularProgress";
import { GetUserMetadata } from "../utils/user";
import { GetUserOrders } from "../utils/api";
import { Orders } from "../order/Orders";

export function Dashboard(): JSX.Element {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [orders, setOrders] = React.useState([]);
  const [userWithCode, setUserWithCode] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const getUserMetadata = async () => {
      const userObj = await GetUserMetadata(
        isAuthenticated,
        user,
        getAccessTokenSilently
      );
      if (userObj !== undefined) {
        const userOrders = await GetUserOrders(userObj);

        if (!userOrders || userOrders?.length === 0) {
          window.location = "/create-order";
        } else {
          setOrders(userOrders);
          setUserWithCode(userObj);
          setIsLoading(false);
        }
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub, isAuthenticated, user]);

  return (
    <Box className="hero-image" style={{ minHeight: "100vh" }}>
      <Box>
        {isLoading ? (
          <div style={{ width: "100%", textAlign: "center", marginTop: "5em" }}>
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <Orders orders={orders} />
        )}
      </Box>
    </Box>
  );
}
