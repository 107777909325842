import * as React from "react";
import { Home } from "./pages/Home";
import { Dashboard } from "./pages/Dashboard";
import { OrderPage } from "./pages/Order";
import { TermsOfUse } from "./pages/TermsOfUse";
import { CreateOrder } from "./pages/CreateOrder";
import { Demo } from "./pages/Demo";
import { HelpCenter } from "./pages/HelpCenter";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Sitemap } from "./global-components/Sitemap";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

export function ArtGenRoutes(): JSX.Element {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<Home />}
        sitemapIndex={true}
        priority={1}
        changefreq={"weekly"}
      />
      <Route
        exact
        path="/dashboard"
        element={<ProtectedRoute component={Dashboard} />}
      />
      <Route
        exact
        path="/order/:id"
        element={<ProtectedRoute component={OrderPage} />}
      />
      <Route
        exact
        path="/create-order"
        element={<ProtectedRoute component={CreateOrder} />}
      />
      <Route exact path="/terms-of-use" element={<TermsOfUse />} />
      <Route exact path="/demo" element={<Demo />} />
      <Route
        exact
        path="/help-center"
        element={<HelpCenter />}
        sitemapIndex={true}
        priority={0.8}
        changefreq={"weekly"}
      />
      <Route path="/sitemap" element={<Sitemap />} />
    </Routes>
  );
}
