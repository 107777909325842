import * as React from "react";
import { Box } from "@mui/material";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import { IsProd } from "../utils/env";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.toString() };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log("failed with error: ", error);
    console.log("failed error info: ", errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          className="hero-image"
          style={{
            minHeight: "100vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box class="order-stage-box">
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div class="choose-product-outer-circle">
                  <div class="choose-product-icon-inner-circle">
                    <CrisisAlertIcon
                      style={{ color: "white", fontSize: "2em" }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h1>Sorry. Something went wrong.</h1>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h2>
                  If you need immediate support, please reach out to
                  support@artgen.store.
                </h2>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h3>We have been notified of the failure.</h3>
              </div>
              {!IsProd() ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h4>{this.state.error}</h4>
                </div>
              ) : null}
            </div>
          </Box>
        </Box>
      );
    }

    return this.props.children;
  }
}
