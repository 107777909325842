import * as React from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { IsSmallSize } from "../../utils/size";
import { GetProducts } from "../../utils/api";
import Skeleton from "@mui/material/Skeleton";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import { ViewProductSpec } from "../../utils/products";
import { GetIPLocation } from "../../utils/location";

export function ChooseProductStage({
  order,
  handleStageButton,
  setModalURL,
}): JSX.Element {
  const [locationObj, setLocationObj] = React.useState(undefined);
  const [selectedProduct, setSelectedProduct] = React.useState(-1);
  const [selectedVariant, setSelectedVariant] = React.useState(-1);
  const [priceEstimates, setPriceEstimates] = React.useState(new Map());

  React.useEffect(() => {
    if (priceEstimates.size > 0) {
      return;
    }

    const grabProductDescr = async () => {
      const locationObj = await GetIPLocation();
      setLocationObj(locationObj);

      const productsVal = await GetProducts();
      if (productsVal) {
        var estimatesMap = new Map();

        productsVal?.products?.forEach((productItem) => {
          if (productItem?.price_estimate_matrix && productItem?.variant?.id) {
            estimatesMap.set(
              productItem?.variant?.id,
              productItem?.price_estimate_matrix
            );
          }
        });

        setPriceEstimates(estimatesMap);
      }
    };

    grabProductDescr();
  }, [order]);

  return (
    <React.Fragment>
      <Box class="order-stage-box">
        <div>
          <div class="choose-product-outer-circle">
            <div class="choose-product-icon-inner-circle">
              <AssignmentTurnedInIcon
                style={{ color: "white", fontSize: "2em" }}
              />
            </div>
          </div>
        </div>
      </Box>
      <Box
        class="order-stage-box medium-text-align-center"
        style={{ marginTop: "0px", textAlign: "center" }}
      >
        <h1 style={{ fontFamily: "Poppins Medium" }}>
          Choose a product{" "}
          <span style={{ fontFamily: "Poppins Thin" }}>for the art</span> to be
          printed on.
          <br></br>
          <span style={{ fontSize: "0.5em", display: "block" }}>
            This can be changed at any point before ordering!
          </span>
        </h1>
      </Box>

      <Box class="centered-order-stage-content large-small">
        <Grid container>
          {products.map((product) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={3}
              key={product.id}
              onClick={() => {
                setSelectedProduct(product.id);
                setSelectedVariant(product.variantID);
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "2.5em",
                }}
              >
                <div>
                  <div
                    class={
                      "product-choice-outer-box" +
                      (selectedProduct === product.id ? " active" : "")
                    }
                  >
                    <div class="product-choice-inner-box">
                      <div class={product.className}></div>
                    </div>
                  </div>
                  <div class="product-choice-name product-box-mobile-view">
                    {product.name}
                    <Tooltip title="Product Specifications">
                      <InfoIcon
                        style={{
                          fontSize: "0.7em",
                          marginLeft: "0.3em",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setModalURL(
                            ViewProductSpec(product.id, product.variantID)
                          );
                        }}
                      />
                    </Tooltip>
                    <br></br>
                    {priceEstimates.get(product.variantID) ? (
                      <ProductEstimate
                        priceEstimates={priceEstimates}
                        variantID={product.variantID}
                        locationObj={locationObj}
                      />
                    ) : (
                      <Skeleton
                        variant="text"
                        sx={{
                          fontSize: "1rem",
                          display: "inline-block",
                          minWidth: "30px",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1em" }}
      >
        <LoadingButton
          size="small"
          loading={false}
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          style={{
            marginLeft: IsSmallSize() ? "0px" : "10px",
            backgroundColor:
              selectedProduct !== -1 ? "#2C3441" : "rgba(0, 0, 0, 0.12)",
            fontFamily: "Source Code Pro",
            borderRadius: "25px",
            marginTop: "15px",
            fontSize: "0.875rem",
            fontWeight: 600,
            padding: "10px 25px 10px 25px",
            marginBottom: "2em",
          }}
          disabled={selectedProduct === -1}
          onClick={() => {
            handleStageButton({
              product: {
                productID: selectedProduct,
                variantID: selectedVariant,
              },
            });
          }}
        >
          SUBMIT PRODUCT
        </LoadingButton>
      </div>
    </React.Fragment>
  );
}

function ProductEstimate({
  priceEstimates,
  variantID,
  locationObj,
}): JSX.Element {
  const estimates = priceEstimates.get(variantID);

  const currencyMap = {
    CA: "CAD",
    US: "USD",
  };

  let val = estimates.USD + " USD";
  if (locationObj && locationObj.country_code) {
    const currencyVal = currencyMap[locationObj.country_code];
    if (currencyVal) {
      const estimateVal = estimates[currencyVal];
      if (estimateVal) {
        val = estimateVal + " " + currencyVal;
      }
    }
  }

  return <React.Fragment>${val}</React.Fragment>;
}

const products = [
  {
    className: "product-choice-sticker",
    name: "Sticker",
    id: 358,
    variantID: 10164,
  },
  {
    className: "product-choice-frame",
    name: "Frame",
    id: 2,
    variantID: 4652,
  },
  {
    className: "product-choice-mug",
    name: "Mug",
    id: 403,
    variantID: 11051,
  },
  {
    className: "product-choice-greeting-card",
    name: "Card",
    id: 568,
    variantID: 14457,
  },
  {
    className: "product-choice-notebook",
    name: "Notebook",
    id: 474,
    variantID: 12141,
  },
  {
    className: "product-choice-facemask",
    name: "Face Mask",
    id: 434,
    variantID: 11520,
  },
  {
    className: "product-choice-mousepad",
    name: "Mousepad",
    id: 518,
    variantID: 13097,
  },
  {
    className: "product-choice-coaster",
    name: "Coaster",
    id: 611,
    variantID: 15662,
  },
];
