import * as React from "react";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Skeleton from "@mui/material/Skeleton";
import { ModalStyles } from "../utils/modal";
import { SanitizeOrderID } from "../utils/order";

export function Orders({ orders }): JSX.Element {
  const [open, setOpen] = React.useState("");
  const handleOpen = (img) => setOpen(img);
  const handleClose = () => setOpen("");

  var rows = orders.map((order) => {
    return {
      id: order.id,
      created_at: new Date(order.last_updated),
      status: order.status,
      images: extractImages(order.base_images),
      product: extractProductData(order),
      estimate: extractEstimate(order),
      payment_needed:
        order && order.status === "initiated"
          ? order.payment_needed
          : undefined,
      is_demo: order.is_demo === true,
    };
  });

  return (
    <Box
      class="centered-full-stage-content"
      style={{
        marginTop: "2em",
        marginBottom: "1em",
        width: "75%",
        minWidth: "19em",
      }}
    >
      <OrdersTotal rows={rows} />
      <OrdersTable rows={rows} handleOpen={handleOpen} />
      <Modal
        open={open !== ""}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyles}>
          <img
            src={open}
            height="100%"
            width="100%"
            style={{ borderRadius: "7px" }}
          ></img>
        </Box>
      </Modal>
    </Box>
  );
}

function extractProductData(order) {
  let productImage =
    order && order.product_info ? order.product_info.default_url : "";
  if (order.mockup_images && order.mockup_images.length > 0) {
    productImage = order.mockup_images[0].url;
  }

  return {
    url: productImage,
    name: order.product_info
      ? order.product_info.name
      : "Product Not Chosen Yet",
    disable_zoom: !(order.mockup_images && order.mockup_images.length > 0),
  };
}

function extractImages(i) {
  var resp = [];

  if (!i) {
    return resp;
  }

  var intervalSize = i.length / 4;
  for (var j = 0; j < i.length; j += intervalSize) {
    resp.push(i[j]);
  }

  return resp;
}

function extractEstimate(order) {
  if (
    order?.fulfillment_info?.total_price &&
    order?.fulfillment_info?.receipt?.currency
  ) {
    return {
      price: order?.fulfillment_info?.total_price,
      currency: order?.fulfillment_info?.receipt?.currency,
    };
  }

  if (!order.estimate) {
    return null;
  }

  return {
    price: order.estimate.price,
    currency: order.estimate.currency,
  };
}

export function OrdersTotal({ rows }): JSX.Element {
  return (
    <span class="orders-total-box">
      Total{" "}
      {rows.length.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      })}{" "}
      Items
    </span>
  );
}

export default function OrdersTable({ rows, handleOpen }) {
  const clickedRow = (row) => {
    if (row.is_demo) {
      window.location = "/demo?order_id=" + row.id;
      return;
    }

    if (
      row &&
      row.payment_needed &&
      row.payment_needed.checkout_url &&
      row.payment_needed.checkout_url !== ""
    ) {
      window.location.href = row.payment_needed.checkout_url;
    } else {
      window.location = "/order/" + row.id;
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{ backgroundColor: "#2C3441" }}>
          <TableRow>
            <TableCell style={{ color: "white" }}>Order Number</TableCell>
            <TableCell align="center" style={{ color: "white" }}>
              Images
            </TableCell>
            <TableCell align="center" style={{ color: "white" }}>
              Date
            </TableCell>
            <TableCell align="center" style={{ color: "white" }}>
              Price
            </TableCell>
            <TableCell align="center" style={{ color: "white" }}>
              Product
            </TableCell>
            <TableCell align="center" style={{ color: "white" }}>
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ cursor: "pointer" }}
                onClick={() => clickedRow(row)}
              >
                <span>{SanitizeOrderID(row.id)}</span>
              </TableCell>
              <TableCell align="center" style={{ minWidth: "250px" }}>
                <OrderImages images={row.images} handleOpen={handleOpen} />
              </TableCell>
              <TableCell align="center" size="small" style={{ width: "10em" }}>
                <span>
                  {row.created_at.toLocaleDateString("en-us", {
                    weekday: "long",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </span>
              </TableCell>
              <TableCell align="center">
                <OrderPrice estimate={row.estimate} />
              </TableCell>
              <TableCell align="center">
                <OrderProduct product={row.product} handleOpen={handleOpen} />
              </TableCell>
              <TableCell
                align="center"
                style={{ cursor: "pointer" }}
                onClick={() => clickedRow(row)}
              >
                <OrderStatus status={row.status} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function OrderProduct({ product, handleOpen }): JSX.Element {
  return (
    <Tooltip title={product.name}>
      {product.url === "" ? (
        <Skeleton variant="rounded" width={60} height={60} />
      ) : (
        <Box class="product-choice-dashboard-box">
          <OrderImage
            url={product.url}
            handleOpen={
              product && product.disable_zoom === false ? handleOpen : ""
            }
            disable_zoom={product && product.disable_zoom === true}
          />
        </Box>
      )}
    </Tooltip>
  );
}

function OrderImages({ images, handleOpen }): JSX.Element {
  return (
    <Grid container style={{ marginTop: "0px" }}>
      {!images || images.length === 0 ? (
        <React.Fragment>
          <Grid xs={6} md={3} class="dashboard-image-container">
            <div class="dashboard-order-img-container">
              <NoOrderImageYet />
            </div>
          </Grid>
          <Grid xs={6} md={3} class="dashboard-image-container">
            <div class="dashboard-order-img-container">
              <NoOrderImageYet />
            </div>
          </Grid>
          <Grid xs={6} md={3} class="dashboard-image-container">
            <div class="dashboard-order-img-container">
              <NoOrderImageYet />
            </div>
          </Grid>
          <Grid xs={6} md={3} class="dashboard-image-container">
            <div class="dashboard-order-img-container">
              <NoOrderImageYet />
            </div>
          </Grid>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {images.map((img) => (
            <Grid xs={6} md={3} class="dashboard-image-container">
              <div class="dashboard-order-img-container">
                <OrderImage url={img.url} handleOpen={handleOpen} />
              </div>
            </Grid>
          ))}
        </React.Fragment>
      )}
    </Grid>
  );
}

function NoOrderImageYet(): JSX.Element {
  return (
    <Tooltip title="No Images Generated Yet">
      <Skeleton variant="rounded" width={60} height={60} />
    </Tooltip>
  );
}

function OrderImage({ url, handleOpen, disable_zoom }): JSX.Element {
  const cursorVal = disable_zoom ? "default" : "pointer";

  return (
    <img
      src={url}
      height="60px"
      width="60px"
      style={{ borderRadius: "7px", cursor: cursorVal }}
      onClick={() => handleOpen(url)}
    ></img>
  );
}

function OrderPrice({ estimate }): JSX.Element {
  let price = <Skeleton variant="text" sx={{ fontSize: "1rem" }} />;
  let tooltip = "Price is calculated after choosing a product";

  if (estimate) {
    price = <span>${estimate.price}</span>;
    tooltip = estimate.currency + " (Estimate)";
  }

  return <Tooltip title={tooltip}>{price}</Tooltip>;
}

function OrderStatus({ status }): JSX.Element {
  const needPaymentStatus = (
    <span
      class="order-status-box"
      style={{
        backgroundColor: "rgb(254, 88, 88, 0.25)",
        color: "rgb(254, 88, 88)",
      }}
    >
      PAY NOW
    </span>
  );
  const defaultStatus = (
    <span
      class="order-status-box"
      style={{
        backgroundColor: "rgb(154, 120, 255, 0.25)",
        color: "rgb(154, 120, 255)",
      }}
    >
      IN PROGRESS
    </span>
  );
  const printingStatus = (
    <span
      class="order-status-box"
      style={{
        backgroundColor: "rgb(88, 149, 254, 0.25)",
        color: "rgb(88, 149, 254)",
      }}
    >
      PRINTING
    </span>
  );
  const cancelledStatus = (
    <span
      class="order-status-box"
      style={{
        backgroundColor: "rgb(44, 52, 65, 0.25)",
        color: "rgb(44, 52, 65)",
      }}
    >
      CANCELED
    </span>
  );
  const shippedStatus = (
    <span
      class="order-status-box"
      style={{
        backgroundColor: "rgb(0, 181, 66, 0.25)",
        color: "rgb(0, 181, 66)",
      }}
    >
      SHIPPED
    </span>
  );

  const statusMap = {
    initiated: needPaymentStatus,
    started: defaultStatus,
    generating_images: defaultStatus,
    generated_images: defaultStatus,
    chosen_product: defaultStatus,
    chosen_image: defaultStatus,
    product_configured: defaultStatus,
    generated_mockups: needPaymentStatus,
    product_ordered: printingStatus,
    product_shipped: shippedStatus,
    failed: cancelledStatus,
  };

  let resp = statusMap[status];
  if (!resp) {
    resp = defaultStatus;
  }

  return resp;
}
