import * as React from "react";
import { Box } from "@mui/material";
import ReactFlagsSelect from "react-flags-select";
import PublicIcon from "@mui/icons-material/Public";
import { SetOrderCountry, GetOrder, GetDemoContent } from "../utils/api";
import {
  WriteQueryParam,
  ParseDemoStageFromQueryParams,
  ParseDemoParamsFromQueryParams,
} from "../utils/demo";

export function ChooseOrderCountry({
  currentCountryCode,
  setOrder,
  user,
  order,
  orderID,
  setModalURL,
  demo,
}): JSX.Element {
  const [selectedCountry, setSelectedCountry] =
    React.useState(currentCountryCode);

  const handleChangeCountry = async (code) => {
    if (selectedCountry === code) {
      return;
    }

    if (demo !== true) {
      await SetOrderCountry(user, orderID, code);

      setSelectedCountry(code);
      setModalURL("");
      order.estimate = undefined;
      order.country_code = code;
      setOrder(order);

      const newOrder = await GetOrder(user, orderID);
      setOrder(newOrder);
    } else if (demo === true) {
      WriteQueryParam("country", code);

      setSelectedCountry(code);
      setModalURL("");
      order.estimate = undefined;
      order.country_code = code;
      setOrder(order);

      const demoParams = await ParseDemoParamsFromQueryParams();
      const demoContent = await GetDemoContent(
        ParseDemoStageFromQueryParams(),
        demoParams
      );
      setOrder(demoContent.order);
    }
  };

  return (
    <div>
      <Box class="order-stage-box">
        <div>
          <div class="choose-product-outer-circle">
            <div class="choose-product-icon-inner-circle">
              <PublicIcon style={{ color: "white", fontSize: "2em" }} />
            </div>
          </div>
        </div>
      </Box>
      <Box class="order-stage-box" style={{ marginTop: "1em" }}>
        <h2 style={{ fontFamily: "Poppins Medium" }}>
          Change order{" "}
          <span style={{ fontFamily: "Poppins Thin" }}>delivery</span> country.
        </h2>
      </Box>
      <Box
        style={{
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "5em",
        }}
      >
        <ReactFlagsSelect
          selected={selectedCountry}
          countries={["US", "CA"]}
          onSelect={(code) => {
            handleChangeCountry(code);
          }}
        />
      </Box>
    </div>
  );
}
