import * as React from "react";
import { Box } from "@mui/material";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Grid from "@mui/material/Grid";
import CircleIcon from "@mui/icons-material/Circle";
import IconButton from "@mui/material/IconButton";
import { Us, Ca } from "react-flags-select";
import Tooltip from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Skeleton from "@mui/material/Skeleton";
import {
  RewindOrder,
  GetOrder,
  GetDemoContent,
  CreateDemoPayment,
  MakeOrderProductPayment,
} from "../../utils/api";
import {
  ParseDemoParamsFromQueryParams,
  ParseDemoStageFromQueryParams,
  WriteQueryParam,
  ParsePreviousDemoOrderFromDemoParams,
  WritePreviousOrderToLocalStorage,
} from "../../utils/demo";
import { IsSmallSize, IsMediumSize } from "../../utils/size";
import InfoIcon from "@mui/icons-material/Info";
import { ViewProductSpec } from "../../utils/products";

export const ChooseNewCountryValue = "CHOOSE_NEW_ORDER_COUNTRY";

export function PreviewOrderStage({
  order,
  handleStageButton,
  setModalURL,
  setOrder,
  user,
  demo,
}): JSX.Element {
  const [mockupImage, setMockupImage] = React.useState(
    order.mockup_images[0].url
  );
  const [rewindingOrder, setRewindingOrder] = React.useState("");
  const [loadingPayment, setLoadingPayment] = React.useState(false);

  let selectedImg = "";
  for (var i = 0; i < order.base_images.length; i++) {
    if (order.base_images[i].usage === "front") {
      selectedImg = order.base_images[i].url;
    }
  }

  const blockWhilePollingForOrderStatus = async () => {
    var pollInterval = setInterval(async () => {
      const tmpOrder = await GetOrder(user, order.id);

      if (
        tmpOrder !== undefined &&
        tmpOrder.estimate &&
        tmpOrder.estimate.price
      ) {
        setOrder(tmpOrder);
        clearInterval(pollInterval);
      }
    }, 1000);
  };

  const handleRewind = async (rewindType) => {
    setRewindingOrder(true);
    if (demo !== true) {
      const resp = await RewindOrder(user, order.id, rewindType);
      if (resp) {
        const tmpOrder = await GetOrder(user, order.id);
        setOrder(tmpOrder);
        setRewindingOrder("");
      }
    } else if (demo === true) {
      WriteQueryParam("stage", rewindType === "change-image" ? 3 : 1);
      const demoParams = await ParseDemoParamsFromQueryParams();
      const demoContent = await GetDemoContent(
        ParseDemoStageFromQueryParams(),
        demoParams
      );
      setOrder(demoContent.order);
      setRewindingOrder("");
    }
  };

  const handleDemoPayment = async () => {
    const params = await ParseDemoParamsFromQueryParams();
    const previousDemoOrder = await ParsePreviousDemoOrderFromDemoParams(
      params
    );
    const demoPayment = await CreateDemoPayment(
      params,
      previousDemoOrder,
      user
    );
    WritePreviousOrderToLocalStorage(params, demoPayment.order_id);
    window.location = demoPayment.charge.checkout_url;
  };

  const handleAuthenticatedPayment = async () => {
    const checkoutLink = await MakeOrderProductPayment(user, order.id);
    window.location = checkoutLink;
  };

  React.useEffect(() => {
    if (order?.estimate?.price) {
      return;
    }

    const handleUseEffect = async () => {
      if (demo !== true && (!order || (order && !order.estimate))) {
        blockWhilePollingForOrderStatus();
      }
    };

    handleUseEffect();
  }, [order?.estimate]);

  return (
    <div class="centered-full-stage-content">
      <Box class="order-stage-box">
        <div>
          <div class="choose-product-outer-circle">
            <div class="choose-product-icon-inner-circle">
              <ShoppingBagIcon style={{ color: "white", fontSize: "2em" }} />
            </div>
          </div>
        </div>
      </Box>
      <Box class="order-stage-box" style={{ marginTop: "0px" }}>
        <h1 style={{ fontFamily: "Poppins Medium" }}>
          Confirm{" "}
          <span style={{ fontFamily: "Poppins Thin" }}>your order.</span>
        </h1>
      </Box>
      {IsMediumSize() ? (
        <OrderConfiguredFor order={order} setModalURL={setModalURL} />
      ) : null}
      <Grid
        container
        style={{ height: "auto", marginTop: "1em", width: "100%" }}
      >
        <Grid
          xs={12}
          md={12}
          lg={6}
          key={mockupImage}
          style={{
            borderRight: IsMediumSize() ? "0px solid #ccc" : "1px solid #ccc",
          }}
        >
          <div class="preview-left-box">
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <div
                    class="product-choice-outer-box large"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setModalURL(mockupImage);
                    }}
                  >
                    <div class="product-choice-inner-box large">
                      <img
                        src={mockupImage}
                        style={{
                          height: "inherit",
                          borderRadius: "25px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {order.mockup_images.length > 1 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "0.5em",
                  }}
                >
                  {order.mockup_images.map((img) => (
                    <IconButton
                      key={img.id}
                      onClick={() => {
                        setMockupImage(img.url);
                      }}
                    >
                      <CircleIcon
                        style={{
                          color: mockupImage === img.url ? "#5895fe" : "",
                        }}
                      />
                    </IconButton>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </Grid>
        <Grid
          xs={12}
          md={12}
          lg={6}
          style={{ padding: "3em", marginTop: "1em" }}
        >
          {!IsMediumSize() ? (
            <OrderConfiguredFor order={order} setModalURL={setModalURL} />
          ) : null}
          <Grid
            container
            spacing={2}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "1em",
              width: "100%",
            }}
          >
            <Grid xs={12} md={6} lg={6}>
              <div>
                <div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <div
                        class="product-choice-outer-box medium active"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setModalURL(selectedImg);
                        }}
                      >
                        <div class="product-choice-inner-box medium">
                          <img
                            src={selectedImg}
                            style={{
                              height: "inherit",
                              borderRadius: "18%",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "1em", textAlign: "center" }}>
                Selected Art
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div class="white-loading-button">
                  <LoadingButton
                    size="small"
                    startIcon={
                      rewindingOrder === "change-image" ? null : (
                        <ArrowBackIcon />
                      )
                    }
                    loading={rewindingOrder === "change-image"}
                    variant="contained"
                    style={{
                      marginLeft: IsSmallSize() ? "0px" : "10px",
                      backgroundColor: "white",
                      color: "black",
                      fontFamily: "Source Code Pro",
                      borderRadius: "25px",
                      marginTop: "15px",
                      marginBottom: "1em",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      minWidth: "13em",
                      padding: "10px 25px 10px 25px",
                      border:
                        rewindingOrder === "change-image"
                          ? "2px solid #ccc"
                          : "0px solid #ccc",
                    }}
                    onClick={() => {
                      handleRewind("change-image");
                    }}
                  >
                    <span
                      style={{
                        color:
                          rewindingOrder === "change-image" ? "white" : "black",
                      }}
                    >
                      CHANGE
                    </span>
                  </LoadingButton>
                </div>
              </div>
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    <div
                      class="product-choice-outer-box medium active"
                      style={{ marginTop: IsSmallSize() ? "3em" : "0em" }}
                    >
                      <div class="product-choice-inner-box medium">
                        <Tooltip title={order.product_info.name}>
                          <img
                            src={order.product_info.default_url}
                            style={{
                              height: "inherit",
                              borderRadius: "25px",
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "1em", textAlign: "center" }}>
                {order.product_info.name}
                <Tooltip title="Product Specifications">
                  <InfoIcon
                    style={{
                      fontSize: "0.7em",
                      marginLeft: "0.3em",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setModalURL(
                        ViewProductSpec(
                          order.product_info.id,
                          order.product_info.variant_id
                        )
                      );
                    }}
                  />
                </Tooltip>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div class="white-loading-button">
                  <LoadingButton
                    size="small"
                    startIcon={
                      rewindingOrder === "change-product" ? null : (
                        <ArrowBackIcon />
                      )
                    }
                    loading={rewindingOrder === "change-product"}
                    variant="contained"
                    style={{
                      marginLeft: IsSmallSize() ? "0px" : "10px",
                      backgroundColor: "white",
                      color: "black",
                      fontFamily: "Source Code Pro",
                      borderRadius: "25px",
                      marginTop: "15px",
                      marginBottom: "1em",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      minWidth: "13em",
                      padding: "10px 25px 10px 25px",
                      border:
                        rewindingOrder === "change-product"
                          ? "2px solid #ccc"
                          : "0px solid #ccc",
                    }}
                    onClick={() => {
                      handleRewind("change-product");
                    }}
                  >
                    <span
                      style={{
                        color:
                          rewindingOrder === "change-product"
                            ? "white"
                            : "black",
                      }}
                    >
                      CHANGE
                    </span>
                  </LoadingButton>
                </div>
              </div>
            </Grid>
          </Grid>
          <div
            style={{
              width: "70%",
              display: "flex",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "2em",
            }}
          >
            <LoadingButton
              size="small"
              endIcon={<ArrowForwardIcon />}
              loading={loadingPayment}
              variant="contained"
              style={{
                marginLeft: IsSmallSize() ? "0px" : "10px",
                backgroundColor: "#2C3441",
                fontFamily: "Source Code Pro",
                borderRadius: "25px",
                marginTop: "15px",
                fontSize: "0.875rem",
                fontWeight: 600,
                minWidth: "13em",
                padding: "10px 25px 10px 25px",
              }}
              onClick={() => {
                setLoadingPayment(true);
                if (demo === true) {
                  handleDemoPayment();
                } else {
                  handleAuthenticatedPayment();
                }
              }}
            >
              $
              {order?.estimate?.price ? (
                <span>
                  {order.estimate.price} {order.estimate.currency}&nbsp;
                </span>
              ) : (
                <Skeleton
                  variant="rounded"
                  style={{ marginRight: "10px", marginLeft: "5px" }}
                  height={"0.875rem"}
                  sx={{ bgcolor: "#ccc" }}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Skeleton>
              )}{" "}
              {IsSmallSize() ? "" : "| ORDER NOW"}
            </LoadingButton>
          </div>
          <div class="prompt-image-box">
            <span class="price-usd-warning">
              Get your order delivered straight to your doorstep at no extra
              cost - free shipping included!
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function OrderConfiguredFor({ order, setModalURL }): JSX.Element {
  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span class="preview-order-name medium-text-align-center">
          {order.product_info.name}{" "}
          <Tooltip title="Correct location information is necessary to place an order.">
            <InfoIcon style={{ fontSize: "0.7em" }} />
          </Tooltip>
        </span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: IsSmallSize() ? "-15px" : "-10px",
          marginBottom: IsSmallSize() ? "20px" : "",
        }}
      >
        <span class="preview-order-country-text">
          Order Configured for :{" "}
          <div
            style={{
              display: "inline-block",
              marginLeft: IsSmallSize() ? "0.2em" : "0.2em",
              marginTop: IsSmallSize() ? "0px" : "",
            }}
          >
            <Tooltip
              title={
                mapToCountryName(order.country_code) + " - Click to Change"
              }
            >
              <IconButton
                onClick={() => {
                  setModalURL(ChooseNewCountryValue);
                }}
              >
                {mapToCountryFlag(order.country_code)}
              </IconButton>
            </Tooltip>
          </div>
        </span>
      </div>
    </React.Fragment>
  );
}

function mapToCountryName(countryCode) {
  switch (countryCode) {
    case "US":
      return "US";
    case "CA":
      return "Canada";
    default:
      return "";
  }
}

function mapToCountryFlag(countryCode) {
  switch (countryCode) {
    case "US":
      return <Us />;
    case "CA":
      return <Ca />;
    default:
      return "";
  }
}
