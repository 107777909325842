export function IsProd() {
  return window.location.origin.indexOf("localhost:3000") < 0;
}

export function BaseURL() {
  return window.location.origin;
}

export function AuthDomain() {
  if (IsProd()) {
    return "artgen-prod.us.auth0.com";
  }

  return "dev-wuzhje5xtqu5ohb7.us.auth0.com";
}

export function AuthClientID() {
  if (IsProd()) {
    return "ZWsPHcq4XId894EVrfr1BGC1x66RfpuM";
  }

  return "nbRVv0iTP6wd4JRu6uYGEtDUNCOkIPgH";
}
