import * as React from "react";
import { AppBar, Button, Link, Toolbar, Typography } from "@mui/material";
import { Link as NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { BaseURL } from "../utils/env";
import { UserLogout, GetUserMetadata } from "../utils/user";
import { CheckInUser, PingServer } from "../utils/api";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HomeIcon from "@mui/icons-material/Home";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { IsSmallSize } from "../utils/size";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import { useSearchParams } from "react-router-dom";

const allowedLoggedOutPaths = ["/"];

export function AppToolbar({ setCountryModalOpen }): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const [needLogin, setNeedLogin] = React.useState(true);
  const [userObj, setUserObj] = React.useState(user);

  React.useEffect(() => {
    if (!user || !isAuthenticated) {
      // Remove this healthcheck when we migrate to a better Heroku server
      PingServer();

      return;
    }

    const getUserMetadata = async () => {
      const userObj = await GetUserMetadata(
        isAuthenticated,
        user,
        getAccessTokenSilently
      );
      if (userObj !== undefined) {
        setUserObj(userObj);
        setNeedLogin(false);

        await CheckInUser(userObj);
      } else if (allowedLoggedOutPaths.indexOf(location.pathname) < 0) {
        navigate("/");
      }
    };

    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  if (window.location.pathname === "/sitemap") {
    return null;
  }

  return (
    <AppBar
      class="hero-image"
      sx={{ zIndex: 0 }}
      color="transparent"
      position="static"
      elevation={0}
    >
      <Toolbar>
        <Typography
          variant="h1"
          sx={{
            fontSize: "1.5rem",
            fontWeight: 700,
            letterSpacing: "0.06rem",
            fontFamily: "Playfair Display, normal",
          }}
        >
          <div style={{ display: "flex" }}>
            <Link
              color="#F6EBFF"
              underline="none"
              to={needLogin ? "/" : "/dashboard"}
              component={NavLink}
            >
              <img
                src="https://d124iw7xdacocx.cloudfront.net/static/logo-new.png"
                alt="artgen-logo"
                height="25px"
                style={{ marginTop: "5px" }}
              ></img>
            </Link>
            {window.location.pathname !== "/" ? (
              <PathTrail pathname={window.location.pathname} />
            ) : (
              ""
            )}
          </div>
        </Typography>

        <span style={{ flexGrow: 1 }} />

        <React.Fragment>
          {needLogin ? (
            <React.Fragment>
              <Link
                underline="none"
                color="#000000"
                children="LOG IN"
                style={{
                  fontWeight: 500,
                  fontFamily: "Source Code Pro Regular",
                  cursor: "pointer",
                }}
                onClick={() => loginWithRedirect()}
              />
              <Button
                variant="contained"
                style={{
                  marginLeft: "10px",
                  backgroundColor: "#2C3441",
                  fontFamily: "Source Code Pro",
                  borderRadius: "25px",
                }}
                onClick={() => loginWithRedirect({ screen_hint: "signup" })}
              >
                <span
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Source Code Pro",
                    fontWeight: 600,
                  }}
                >
                  SIGN UP
                </span>
              </Button>
              {showResetDemoButton(searchParams) ? <ResetDemoButton /> : null}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {showResetDemoButton(searchParams) ? <ResetDemoButton /> : null}
              <Button
                variant="contained"
                style={{
                  marginLeft: IsSmallSize() ? "5px" : "10px",
                  backgroundColor: "#2C3441",
                  fontFamily: "Source Code Pro",
                  borderRadius: "25px",
                  minHeight: "40px",
                }}
                onClick={() => {
                  if (window.location.pathname.indexOf("/create-order") < 0) {
                    window.location = "/create-order";
                  }
                }}
              >
                <span
                  style={{
                    color: "#FFFFFF",
                    fontFamily: "Source Code Pro",
                    fontWeight: 600,
                  }}
                >
                  CREATE ORDER
                </span>
              </Button>
              <AccountMenu
                user={userObj}
                logoutFn={() => {
                  UserLogout();
                  logout({ returnTo: BaseURL() });
                }}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      </Toolbar>
    </AppBar>
  );
}

function showResetDemoButton(searchParams) {
  return (
    window.location.pathname.startsWith("/demo") &&
    !searchParams.get("order_id")
  );
}

export default function AccountMenu({ user, logoutFn }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            style={{
              marginLeft: IsSmallSize() ? "5px" : "10px",
              paddingLeft: "0px",
            }}
          >
            <Avatar
              sx={{ width: 40, height: 40 }}
              style={{ backgroundColor: "rgb(44, 52, 65)" }}
            ></Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/*        <MenuItem>
          <ListItemIcon>
            <AccountCircleOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>*/}
        <MenuItem
          onClick={() => {
            window.location = "/dashboard";
          }}
        >
          <ListItemIcon>
            <HomeIcon fontSize="small" />
          </ListItemIcon>
          My Orders
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.location = "/demo";
          }}
        >
          <ListItemIcon>
            <BuildCircleIcon fontSize="small" />
          </ListItemIcon>
          Demo
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.location = "/help-center";
          }}
        >
          <ListItemIcon>
            <InfoOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Help Center
        </MenuItem>
        {/*        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>*/}
        <MenuItem onClick={() => logoutFn()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

function PathTrail({ pathname }): JSX.Element {
  if (pathname[pathname.length - 1] === "/") {
    pathname = pathname.slice(0, -1);
  }

  const pathMap = {
    "/dashboard": "My Orders",
    "/create-order": "Create Order",
    "/terms-of-use": "Terms of Use",
    "/help-center": "Help Center",
  };

  let val = pathMap[pathname];
  if (!val) {
    if (pathname.startsWith("/order/")) {
      val = "Order";
    } else if (pathname.startsWith("/demo")) {
      val = "Demo";
    } else {
      val = pathname.substring(1);
    }
  }

  return <span class="path-trail onlydesk">/ {val}</span>;
}

function ResetDemoButton(): JSX.Element {
  const handleButton = () => {
    window.location = "/demo";
  };

  return (
    <React.Fragment>
      {IsSmallSize() ? (
        <IconButton
          onClick={() => {
            handleButton();
          }}
          style={{
            backgroundColor: "#2C3441",
            color: "#FFFFFF",
            marginLeft: "5px",
          }}
        >
          <RestartAltIcon />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          style={{
            marginLeft: "10px",
            backgroundColor: "#2C3441",
            fontFamily: "Source Code Pro",
            borderRadius: "25px",
          }}
          onClick={() => {
            handleButton();
          }}
          endIcon={<RestartAltIcon />}
        >
          <span
            style={{
              color: "#FFFFFF",
              fontFamily: "Source Code Pro",
              fontWeight: 600,
            }}
          >
            RESET DEMO
          </span>
        </Button>
      )}
    </React.Fragment>
  );
}
