import * as React from "react";
import { Box, Button } from "@mui/material";
import BrushIcon from "@mui/icons-material/Brush";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import { IsSmallSize } from "../../utils/size";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

export function ChoosePromptStage({
  order,
  handleStageButton,
  demo,
  demoPrompts,
}): JSX.Element {
  const [promptValue, setPromptValue] = React.useState("");
  const [usedDemoPrompts, setUsedDemoPrompts] = React.useState(new Map());

  const [chipData, setChipData] = React.useState([
    { key: 0, label: "photo realistic" },
    { key: 1, label: "oil on canvas" },
    { key: 2, label: "hyper-realistic" },
  ]);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  const handleClick = (chipClicked) => () => {
    let spacer = " ";

    if (
      promptValue.length === 0 ||
      (promptValue.length > 0 && promptValue[promptValue.length - 1] == " ")
    ) {
      spacer = "";
    }

    setPromptValue(promptValue + spacer + chipClicked.label);
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipClicked.key)
    );
  };

  React.useEffect(() => {
    let newUsedPromptsMap = new Map();
    order?.prompt_and_images?.forEach((itm) => {
      newUsedPromptsMap.set(itm.prompt, true);
    });
    setUsedDemoPrompts(newUsedPromptsMap);
  }, [demoPrompts, order]);

  const exampleAdditionsJSX = chipData.map((data) => {
    let icon;

    return (
      <Chip
        key={data.key}
        label={data.label}
        onClick={handleClick(data)}
        onDelete={handleDelete(data)}
        style={{
          color: "white",
          marginTop: "5px",
          marginBottom: "5px",
          marginRight: "5px",
          backgroundColor: "#5895FE",
          fontWeight: 600,
        }}
      />
    );
  });

  const promptInputTextOnChange = function (input) {
    setPromptValue(input.target.value);
  };

  return (
    <React.Fragment>
      <Box class="order-stage-box">
        <div>
          <div class="choose-product-outer-circle">
            <div class="choose-product-icon-inner-circle">
              <BrushIcon style={{ color: "white", fontSize: "2em" }} />
            </div>
          </div>
        </div>
      </Box>
      <Box
        class="order-stage-box medium-text-align-center"
        style={{ marginTop: "0px" }}
      >
        <h1 style={{ fontFamily: "Poppins Medium" }}>
          Design <span style={{ fontFamily: "Poppins Thin" }}>your art</span>{" "}
          with a Prompt
        </h1>
      </Box>
      <Box
        style={{ textAlign: "center", marginTop: "2em", paddingBottom: "2em" }}
      >
        {demo === true ? (
          <React.Fragment>
            <DemoPromptTable
              order={order}
              prompts={demoPrompts}
              selectedPrompt={promptValue}
              setSelectedPrompt={setPromptValue}
              usedDemoPrompts={usedDemoPrompts}
            />
            <div style={{ marginTop: "1em", fontStyle: "italic" }}>
              <span>
                The Demo only supports predefined prompts, please{" "}
                <Link
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.location = "/create-order";
                  }}
                >
                  create an order
                </Link>{" "}
                to use whatever prompt you want!
              </span>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <CustomizedInputBase
              value={promptValue}
              onChange={promptInputTextOnChange}
            />
            <Box style={{ marginTop: "1em" }}>{exampleAdditionsJSX}</Box>
          </React.Fragment>
        )}
      </Box>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1em" }}
      >
        <LoadingButton
          size="small"
          endIcon={<ArrowForwardIcon />}
          loading={false}
          variant="contained"
          style={{
            marginLeft: "10px",
            backgroundColor:
              promptValue.length >= 5 ? "#2C3441" : "rgba(0, 0, 0, 0.12)",
            fontFamily: "Source Code Pro",
            borderRadius: "25px",
            marginTop: "15px",
            marginBottom: "2em",
            fontSize: "0.875rem",
            fontWeight: 600,
            padding: "10px 25px 10px 25px",
            color: "white",
          }}
          disabled={promptValue.length <= 5}
          onClick={() => {
            handleStageButton({
              prompt: promptValue,
            });
          }}
        >
          SUBMIT PROMPT
        </LoadingButton>
      </div>
    </React.Fragment>
  );
}

function CustomizedInputBase({ value, onChange }) {
  return (
    <TextField
      label="Enter the Prompt"
      focused
      style={{ width: "50%", minWidth: "19em" }}
      value={value}
      onChange={onChange}
    />
  );
}

function DemoPromptTable({
  order,
  prompts,
  selectedPrompt,
  setSelectedPrompt,
  usedDemoPrompts,
}) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const handleChange = (event, prompt) => {
    if (event.target.checked === false) {
      setSelectedPrompt("");
    } else {
      setSelectedPrompt(prompt);
    }
  };

  return (
    <div
      style={{ overflow: "auto" }}
      class="centered-order-stage-content large-small"
    >
      <Table>
        <TableHead>
          <TableRow
            style={{
              backgroundColor: "rgb(44 52 65 / 75%)",
              height: "35px",
            }}
          >
            <TableCell
              style={{
                color: "white",
                fontFamily: "Poppins Medium",
                display: "flex",
                alignItems: "center",
                minHeight: "5em",
              }}
            >
              {IsSmallSize()
                ? "Choose Prompt"
                : "Choose " +
                  chooseDemoPromptWording(order) +
                  " of " +
                  (prompts.length - usedDemoPrompts.size) +
                  " Available Demo Prompts"}

              {selectedPrompt.length > 0 ? (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#2C3441",
                    fontFamily: "Source Code Pro",
                    borderRadius: "25px",
                    marginLeft: "auto",
                  }}
                  onClick={() => {
                    setSelectedPrompt("");
                  }}
                >
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontFamily: "Source Code Pro",
                      fontWeight: 600,
                      fontSize: "0.75em",
                    }}
                  >
                    {IsSmallSize() ? "Clear" : "Clear Selection"}
                  </span>
                </Button>
              ) : null}
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
      <div style={{ overflow: "auto", height: "40vh" }}>
        <Table style={{ tableLayout: "fixed" }}>
          <TableBody>
            {prompts.map((prompt, i) => {
              if (usedDemoPrompts.get(prompt) === true) {
                return null;
              }

              return (
                <TableRow key={i}>
                  <TableCell style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      {...label}
                      onChange={(event) => {
                        handleChange(event, prompt);
                      }}
                      checked={selectedPrompt === prompt}
                      icon={<CircleOutlinedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                    <span
                      style={{
                        fontFamily: "Poppins",
                        height: "auto",
                        lineHeight: 2,
                      }}
                    >
                      <FormatQuoteIcon
                        style={{ transform: "scaleX(-1)", fontSize: "1.5em" }}
                      />
                      {prompt}
                      <FormatQuoteIcon style={{ fontSize: "1.5em" }} />
                    </span>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

function chooseDemoPromptWording(order) {
  switch (order?.prompt_and_images?.length) {
    case 1:
      return "Your Second";
    case 2:
      return "Your Third";
    case 3:
      return "Your Fourth";
    default:
      return "Your First";
  }
}
